import React from "react";
import { useIsSmallScreen } from "hooks/adaptivity";
import { Group, Panel, PanelHeader } from "@vkontakte/vkui";

const FirstLevelLayout = ({ nav, children }) => {

  const isSmallScreen = useIsSmallScreen();

  return (
    <Panel nav={nav}>
      {isSmallScreen &&
        <PanelHeader
          separator={"true"}
        >
          Афиша
        </PanelHeader>
      }
      <Group
        separator={"hide"}
        style={
          isSmallScreen ? {} : {
            minHeight: 600,
            boxSizing: "border-box",
          }
        }
      >
        {children}
      </Group>
    </Panel>
  );
};

export default FirstLevelLayout;