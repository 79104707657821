import { Button, ButtonGroup, File } from "@vkontakte/vkui";
import { Icon28Delete, Icon28EditOutline } from "@vkontakte/icons";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import DefaultCover from "../../assets/images/default_cover.jpg";
import { useCropperModal, useCropperResult, useDispatchCropperResult } from "hooks/useCropperModal";
import CoverImage from "components/CoverImage/CoverImage";

const CoverInput = (props) => {
    let [imageSrc, setImageSrc] = useState(props.src)
    let { remove: removeCropperCache } = useDispatchCropperResult(props.modalKey);
    useEffect(() => {
        removeCropperCache();
    }, []);

    let { show: showCropperModal } = useCropperModal();
    let { result: cropperResult } = useCropperResult(props.modalKey);

    useEffect(() => {
        setImageSrc(cropperResult ? cropperResult : props.src);
    }, [cropperResult, props.src])

    const deleteImage = () => {
        let result = {
            cover_present: 0
        }
        setImageSrc(null)
        props.onChange(result)
        removeCropperCache();
    }

    const imageChanged = (base64) => {
        if (!base64) return
        let result = {
            cover_present: !!base64 ? 1 : 0,
            cover_base64: base64
        }
        props.onChange(result)
    }

    /**
     * @param file {File}
     */
    function openPopout(file) {
        showCropperModal(props.modalKey, {
            file: file,
            cropShape: "rect",
            width: 1530,
            height: 384,
            aspect: 510 / 128,
        });
    }


    const onImageInputValueChanged = (e) => {
        let file = Array.from(e.target.files)[0];
        e.target.value = null
        openPopout(file);
    }

    useEffect(() => {
        imageChanged(cropperResult);
    }, [cropperResult])


    return (<>
        <div style={{position: "relative"}}>
            <CoverImage src={imageSrc ?? DefaultCover} />
            <ButtonGroup style={{ position: "absolute", top: 16, right: 16, zIndex: 2 }}>
                {!imageSrc &&
                    <File mode={"primary"}
                          appearance={"overlay"}
                          onChange={onImageInputValueChanged}
                          type="file"
                          id="coverupload"
                          accept="image/jpeg, image/png"
                          before={<Icon28EditOutline width={16} role="presentation"/>}>
                        Добавить обложку
                    </File>
                }
                {imageSrc &&
                    <Button before={<Icon28Delete width={16}/>} mode={"primary"}
                            appearance={"overlay"} onClick={deleteImage}>Удалить</Button>
                }
            </ButtonGroup>
        </div>
    </>)
}

CoverInput.propTypes = {
    src: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    modalKey: PropTypes.string.isRequired,
}

export default CoverInput