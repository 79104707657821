import {
  Button, Div, Headline, Separator, SimpleCell, Switch, Tooltip,
} from "@vkontakte/vkui";
import React, { useEffect, useState } from "react";
import { Icon16Add, Icon24HelpOutline } from "@vkontakte/icons";
import { useIsSmallScreen } from "hooks/adaptivity";
import { useIsAppAdmin } from "hooks/params";
import GroupListTour from "components/list/GroupList";
import {
  useIsSubscribed, useIsSubscriptionsEnabled, useIsUserLoaded, userReload,
} from "hooks/user";
import { http } from "api/axiosConfig";
import bridge from "@vkontakte/vk-bridge";
import ErrorSnack from "components/ErrorSnack";
import Tip from "components/Tip/Tip";
import PremiumBanner from "components/banner/PremiumBanner";
import { useParams, useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { GROUP_APP_TABS, routes, SCHEDULE_TABS } from "routes";
import Tags from "components/tags/Tags";
import { getParamOrDefault } from "utils/navigation";


const GroupSchedule = () => {

  let routeNavigator = useRouteNavigator();

  const [snackbar, setSnackbar] = useState(null);
  const [checked, setChecked] = useState(!!useIsSubscribed());

  let { tab } = useParams({ tab: GROUP_APP_TABS.SCHEDULE }) ?? {};

  const activeTab = getParamOrDefault(tab, SCHEDULE_TABS);

  let isSubscribed = useIsSubscribed();

  useEffect(() => {
    if (isSubscribed !== undefined) {
      setChecked(isSubscribed);
    }
  }, [isSubscribed]);


    const smallScreen = useIsSmallScreen();
    const className = smallScreen ? "" : "no-flex-grow"

  const isAppAdmin = useIsAppAdmin();
  const isUserLoaded = useIsUserLoaded();
  let isSubscriptionsEnabled = useIsSubscriptionsEnabled();

    const tooltipText = <>Подключите уведомления,<br/>чтобы получать информацию<br/>о мероприятиях сообщества.</>;
    const tooltip = <Tooltip text={tooltipText}>
        <Icon24HelpOutline fill={"var(--vkui--color_icon_secondary)"} style={{cursor: "pointer"}}/>
    </Tooltip>;

    function addEvent() {
      void routeNavigator.push(routes.default_view.add_event);
    }

    function handleSubscription(e) {
        let isChecked = e.target.checked

        setChecked(isChecked)

        if (!isChecked) {
            setIsSubscribe(false)
        } else {
            bridge.send('VKWebAppAllowNotifications', {})
                .then((data) => {
                    if (data && data.result) {
                        setIsSubscribe(true)
                    } else {
                        setChecked(false)
                    }
                })
                .catch((reason) => {
                    setChecked(!isChecked)
                    if (reason.error_data && reason.error_data.error_code === 4) { // Юзер отказался
                        return
                    }
                    setSnackbar(<ErrorSnack onClose={() => setSnackbar(null)} reason={reason}/>)
                })
        }
    }

    function setIsSubscribe(isSubscribe) {
        http.post('/frame/subscriptions/subscribe/', {
            enable: isSubscribe ? 1 : 0,
        }).then(() => {
            setChecked(isSubscribe)
            userReload()
        }).catch(reason => {
            setChecked(!isSubscribe)
            setSnackbar(<ErrorSnack onClose={() => setSnackbar(null)} reason={reason}/>)
        })
    }

    return (<>
      {isSubscriptionsEnabled && <>
        <SimpleCell
          hasHover={false}
          hasActive={false}
          className={className}
          indicator={tooltip}
          after={<Switch
            checked={checked}
            onChange={handleSubscription}
          />}
        >
          <Headline weight={"semibold"}>Уведомления</Headline>
        </SimpleCell>
        <Separator />
      </>
        }

        <Tip/>

        {isAppAdmin && smallScreen &&
          <>
            <Div>
              <Button
                disabled={!isUserLoaded}
                onClick={addEvent}
                stretched={true}
                before={<Icon16Add />}
                size={"m"}
              >
                Добавить мероприятие
              </Button>
            </Div>
            <Separator />
            </>
        }

        <Tags/>

        <PremiumBanner/>

      {activeTab === SCHEDULE_TABS.UPCOMING &&
        <GroupListTour type={"actual"} />
      }
      {activeTab === SCHEDULE_TABS.PAST &&
        <GroupListTour type={"past"} />
      }
      {activeTab === SCHEDULE_TABS.TAG &&
        <GroupListTour type={"tag"} />
      }
        {snackbar}
    </>)
}

export default GroupSchedule