import React from "react";
import { Group, Panel, PanelHeader, PanelHeaderBack } from "@vkontakte/vkui";
import { useFirstPageCheck, useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";

import "./Layout.css";
import { useIsSmallScreen } from "hooks/adaptivity";

const SecondLevelLayout = ({ nav, children }) => {
  let routeNavigator = useRouteNavigator();
  let isFirstPage = useFirstPageCheck();

  const isSmallScreen = useIsSmallScreen();
  function backClicked() {
    if (isFirstPage) {
      void routeNavigator.replace(routes.default_view.resolver);
    } else {
      void routeNavigator.back();
    }
  }

  return (
    <Panel
      nav={nav}
      className="Panel__fullScreen"
    >
      <PanelHeader
        separator={"true"}
        before={<PanelHeaderBack onClick={backClicked} />}
      >
      </PanelHeader>
      <Group
        separator={"hide"}
        style={
          isSmallScreen ? {} : {
            minHeight: 600,
            boxSizing: "border-box",
          }
        }
      >
        {children}
      </Group>
    </Panel>
  );
};

export default SecondLevelLayout;