import { createContext, useState } from "react";

/**
 * @type {React.Context<CropperModalParams>}
 */
export const CropperModalParamsContext = createContext(undefined);

/**
 * @type {React.Context<React.Dispatch<CropperModalParams>>}
 */
export const CropperModalParamsDispatchContext = createContext(undefined);

/**
 * @type {React.Context<CropperResult>}
 */
export const CropperResultContext = createContext(undefined);

/**
 * @type {React.Context<React.Dispatch<CropperResult>>}
 */
export const CropperResultDispatchContext = createContext(undefined);

export const ImageDataProvider = ({ children }) => {

  /**
   * @type {CropperModalParams}
   */
  let initialCropperModalParams = {
    currentKey: null,
    imageParams: {},
  };

  /**
   * @type {CropperResult}
   */
  let initialCropperResult = {
    results: {},
  };

  const [cropperModalParams, setCropperModalParams] = useState(initialCropperModalParams);

  const [cropperResult, setCropperResult] = useState(initialCropperResult);

  return (
    <CropperModalParamsContext.Provider value={cropperModalParams}>
      <CropperModalParamsDispatchContext.Provider value={setCropperModalParams}>
        <CropperResultContext.Provider value={cropperResult}>
          <CropperResultDispatchContext.Provider value={setCropperResult}>
            {children}
          </CropperResultDispatchContext.Provider>
        </CropperResultContext.Provider>
      </CropperModalParamsDispatchContext.Provider>
    </CropperModalParamsContext.Provider>
  );
};
