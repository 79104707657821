import request from "api/axiosConfig";

export const TipsApi = {

  get: async function() {
    return await request({
      url: `/frame/tip`,
      method: "GET",
    });
  },
};