import bridge from "@vkontakte/vk-bridge";
import { http } from "api/axiosConfig";

class VKActions {

  static init() {
    void bridge.send("VKWebAppInit");
  }

  static observeConfigurationChanges() {
    bridge.subscribe((e) => {
      if (e.detail.type === "VKWebAppUpdateConfig") {
        if (e.detail.data.scheme === "bright_light") {
          this.applyViewSettings(false);
        } else if (e.detail.data.scheme === "space_gray") {
          this.applyViewSettings(true);
        }
      }
    });
  }


  static installApp(from) {
    if (bridge.supports("VKWebAppAddToCommunity")) {
      bridge
        .sendPromise("VKWebAppAddToCommunity", {})
        .then((e) => {
          if (from) {
            let group_id = e.group_id;
            console.log(from);
            console.log(group_id);

            window.dataLayer.push({
              event: from,
              landing_referral: group_id,
            });
          }
        })
        .catch((e) => {
        });
    }
  }

  static copyText(text) {
    if (bridge.supports("VKWebAppCopyText")) {
      bridge.send("VKWebAppCopyText", { text: text });
    } else {
      VKActions._copyDirectly(text);
    }
  }

  static share(link) {
    if (bridge.supports("VKWebAppShare")) {
      void bridge.send(
        "VKWebAppShare",
        {
          link: link,
        },
      );
    }
  }


  static applyViewSettings(isDark) {
    if (bridge.supports("VKWebAppSetViewSettings")) {
      void bridge.send(
        "VKWebAppSetViewSettings",
        {
          status_bar_style: isDark ? "light" : "dark",
          action_bar_color: isDark ? "#19191A" : "#FFFFFF",
        },
      );
    }
  }

  static _copyDirectly(text) {
    var input = document.createElement("textarea");
    input.innerHTML = text;
    let activeElement = document.activeElement;
    activeElement.appendChild(input);
    input.select();
    var result = document.execCommand("copy");
    activeElement.removeChild(input);
    return result;
  }

  static loadEvent(link, callback) {
    let path = this._getVKPathIfCan(link);
    if (path) {
      http.post("frame/utils/event/", { path: path }).then((json) => {
        if (json.data.data.result !== false) {
          callback(json.data.data);
        }
      });
    }
  }

  static _getVKPathIfCan(link) {
    try {
      let url = new URL(link);
      let path = url.pathname.replace(/^\/|\/$/g, "");
      if (path.includes("/") || path.length === 0) {
        return false;
      }
      if (path.startsWith("event")) {
        let capturingRegex = /event(?<event_id>\d+)/i;
        let found = path.match(capturingRegex);
        return found.groups.event_id;
      }
      return path;
    } catch (e) {
      return false;
    }
  }


  static setBannerCloseTimeStamp(groupId) {
    bridge.send("VKWebAppStorageSet", {
      key: "premium_banner_close_" + groupId,
      value: new Date().getTime().toString(),
    })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  static getBannerCloseTimeStamp(groupId, callback) {
    bridge.send("VKWebAppStorageGet", {
      keys: ["premium_banner_close_" + groupId],
    })
      .then((data) => {
        if (data.keys && data.keys.length === 1) {
          callback(data.keys[0].value);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }
}

export default VKActions;
