import { singletonHook } from "react-singleton-hook";
import { useEffect, useState } from "react";
import bridge from "@vkontakte/vk-bridge";
import { useAppId } from "./params";
import { isDev } from "utils/development";


let initialState = {
  token: null,
  error: null,
};

let globalSetReload = () => {
  throw new Error(`you must useToken before setting its state`);
};


const useToken = singletonHook(
  initialState, () => {
    if (isDev()) {
      return {
        error: null,
        token: "dev",
      };
    }

    const [token, setToken] = useState(null);
    const [error, setError] = useState(null);
    const [reload, setReload] = useState(Date.now());

    globalSetReload = setReload;

    useEffect(() => {
      console.log("get token");
      bridge.send("VKWebAppGetAuthToken", { app_id: useAppId(), scope: "" })
        .then(value => {
          console.log("success token");
          setToken(value.access_token);
          setError(null);
        })
        .catch(reason => {
          console.log("error token");
          setError(reason);
        });
    }, [reload]);

    return {
      token: token,
      error: error,
    };
  });

export default useToken;

export const tokenReload = () => globalSetReload(Date.now());