import {
    Badge, HorizontalScroll, Separator, Tabs, TabsItem,
} from "@vkontakte/vkui";
import React from "react";
import { ConditionalWrapper } from "components/ConditionalWrapper";
import { useIsSmallScreen } from "hooks/adaptivity";
import { Icon16Crown } from "@vkontakte/icons";
import { useIsProfileApp } from "hooks/params";
import { useWidget } from "hooks/user";
import { useActiveVkuiLocation, useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";

const AdminNav = () => {
    const routeNavigator = useRouteNavigator();
    const { tab: activeTab } = useActiveVkuiLocation();

    let isProfileApp = useIsProfileApp();

    const widget = useWidget();
    const widgetDisabled = widget && !widget.enabled;

    const items = [
        {
            panel: routes.default_view.group_app.schedule,
            title: "Афиша",
            icon: null,
            show: true,
            status: null,
        },
        {
            panel: routes.default_view.group_app.premium,
            title: "Премиум",
            icon: <Icon16Crown/>,
            show: true,
            status: null,
        },
        {
            panel: routes.default_view.group_app.mailings,
            title: "Рассылки",
            icon: null,
            show: !isProfileApp,
            status: null,

        },
        {
            panel: routes.default_view.group_app.widget,
            title: "Виджет",
            icon: null,
            show: !isProfileApp,
            status: widgetDisabled ? <Badge mode="prominent" /> : null,
        },
        {
            panel: routes.default_view.group_app.settings,
            title: "Настройки",
            icon: null,
            show: true,
            status: null,
        },
        {
            panel: routes.default_view.group_app.embed,
            title: "Для сайта",
            icon: null,
            show: true,
            status: null,
        }
    ]

    const openSupport = () => {
        window.open('https://vk.com/im?sel=-143704247', '_blank').focus()
    }

    const smallScreen = useIsSmallScreen();

    const go = e => {
        void routeNavigator.replace(e.currentTarget.dataset.to);
    }

    const isSelected = (item) => {
        return item.panel.id === activeTab;
    };

    return <>
        <Tabs
          mode={"accent"}
          withScrollToSelectedTab={true}
        >
            <ConditionalWrapper
              condition={smallScreen}
              wrapper={children => <HorizontalScroll>{children}</HorizontalScroll>}
            >
                {items
                  .filter(value => value.show)
                  .map((item) => <TabsItem
                    before={item.icon}
                    key={item.title}
                    onClick={go}
                    data-to={item.panel.path}
                    status={item.status}
                    selected={isSelected(item)}
                  >
                      {item.title}
                  </TabsItem>)}

                <TabsItem
                  onClick={openSupport}
                  selected={false}
                >
                    Поддержка
                </TabsItem>

            </ConditionalWrapper>
        </Tabs>
        <Separator />
    </>
}

export default AdminNav