import { Button, Div } from "@vkontakte/vkui";
import { Icon16Add } from "@vkontakte/icons";
import { useIsSmallScreen } from "hooks/adaptivity";
import { useIsAppAdmin } from "hooks/params";
import TagsContent from "components/tags/TagsContent";
import { useIsUserLoaded, useTags } from "hooks/user";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";

const Tags = () => {

  const smallScreen = useIsSmallScreen();

  let routeNavigator = useRouteNavigator();

  const isAppAdmin = useIsAppAdmin();
  const isUserLoaded = useIsUserLoaded();

  const tags = useTags() ?? [];

  function addEvent() {
    void routeNavigator.push(routes.default_view.add_event);
  }

  return <>
    {!smallScreen && <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >

      <div
        style={{
          width: 650,
        }}
      >
        <TagsContent mode={"overflow"} />
      </div>

      <div
        style={{
          width: "100%",
          maxWidth: 250,
          textAlign: "end",
        }}
      >

        <Div>
          {isAppAdmin &&
            <Button
              disabled={!isUserLoaded}
              mode={"secondary"}
              onClick={addEvent}
              stretched={true}
              before={<Icon16Add />}
              size={"m"}
            >
              Добавить мероприятие
            </Button>
          }
          {!isAppAdmin &&
            <Button
              href={"https://vk.com/@dvizh_app-start"}
              target={"_blank"}
              mode={"tertiary"}
              stretched={false}
              size={"m"}
            >
              Создать свою афишу
            </Button>
          }
        </Div>
      </div>
    </div>
    }
    {smallScreen &&
      <TagsContent mode={tags.length === 0 && !isAppAdmin ? "fixed" : "overflow"} />
    }
  </>;
};

export default Tags;