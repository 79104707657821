import { useQuery } from "react-query";
import { TipsApi } from "api/tips/api";
import { useIsAppAdmin } from "hooks/params";

export const useTip = () => {
  return useQuery(
    ["tip"],
    () => TipsApi.get(),
    {
      enabled: useIsAppAdmin(),
      select: (data) => data.data.tip,
    },
  );
};
