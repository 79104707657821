export const Badges = [
  "PinCircleFillBlue",
  "FireCircleFillRed",
  "BookmarkCircleFillYellow",
  "SongCircleFillViolet",
  "GlobeCircleFillBlue",
  "GiftCircleFillRed",
  "FavoriteCircleFillYellow",
  "AdvertisingCircleFillRed",
  "GameCircleFillBlue",
  "DiscussionsCircleFillGreen",
  "CakeCircleFillRaspberryPink",
  "CalendarCircleFillRed",
  "CheckCircleFillYellow",
  "LikeCircleFillRed",
  "LightbulbCircleFillYellow",
  "PopupStickersCircleFillRaspberryPinkProduct",
  "RoubleCircleFillBlue",
  "StarsCircleFillViolet",
  "Hearts2CircleFillTwilight",
  "Users3CircleFillBlue",
];

export class LifecycleStorage {

  static currentEvent = undefined;

  static setCurrentEvent(value) {
    LifecycleStorage.currentEvent = value;
  }
}