import React, { lazy, Suspense } from "react";
import AdminNav from "components/AdminNav";
import { useIsAppAdmin } from "hooks/params";
import { useActiveVkuiLocation } from "@vkontakte/vk-mini-apps-router";
import { GROUP_APP_TABS } from "routes";
import GroupSchedule from "pages/Group/GroupSchedule/GroupSchedule";
import { PanelSpinner } from "@vkontakte/vkui";
import { getParamOrDefault } from "utils/navigation";

const Premium = lazy(() => import( "pages/Group/Premium/Premium"));
const Subscriptions = lazy(() => import( "pages/Group/Subscriptions/Subscriptions"));
const Embed = lazy(() => import( "pages/Group/Embed"));
const Widget = lazy(() => import( "pages/Group/Widget"));
const Settings = lazy(() => import( "pages/Group/Settings"));


const GroupAppRoot = () => {
  const showAdminNav = useIsAppAdmin();
  const { tab } = useActiveVkuiLocation();

  const activeTab = getParamOrDefault(tab, GROUP_APP_TABS);

  return (
    <>
      {showAdminNav && <AdminNav />}

      {activeTab === GROUP_APP_TABS.SCHEDULE &&
        <GroupSchedule />
      }
      {activeTab === GROUP_APP_TABS.PREMIUM &&
        <Suspense fallback={<PanelSpinner />}>
          <Premium />
        </Suspense>
      }
      {activeTab === GROUP_APP_TABS.MAILINGS &&
        <Suspense fallback={<PanelSpinner />}>
          <Subscriptions />
        </Suspense>
      }
      {activeTab === GROUP_APP_TABS.WIDGET &&
        <Suspense fallback={<PanelSpinner />}>
          <Widget />
        </Suspense>
      }
      {activeTab === GROUP_APP_TABS.EMBED &&
        <Suspense fallback={<PanelSpinner />}>
          <Embed />
        </Suspense>
      }
      {activeTab === GROUP_APP_TABS.SETTINGS &&
        <Suspense fallback={<PanelSpinner />}>
          <Settings />
        </Suspense>
      }
    </>
  );
};

GroupAppRoot.propTypes = {};

export default GroupAppRoot;