import React, { useCallback, useEffect, useState } from "react";
import {Button, Div, ModalPage, ModalPageHeader} from "@vkontakte/vkui";
import PropTypes from "prop-types";
import Cropper from "react-easy-crop";
import {getOrientation} from 'get-orientation/browser'
import getCroppedImg, {getRotatedImage} from './utils'
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { useCropperModalParams, useDispatchCropperResult } from "hooks/useCropperModal";

const ORIENTATION_TO_ANGLE = {
    '3': 180,
    '6': 90,
    '8': -90,
}

const CropperModal = (props) => {

    let routeNavigator = useRouteNavigator();
    let { set: setCropperResult } = useDispatchCropperResult();

    const [imageSrc, setImageSrc] = useState(null)
    const [zoom, setZoom] = useState(1)
    const [crop, setCrop] = useState({x: 0, y: 0})
    const [rotation, setRotation] = useState(0)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

    const params = useCropperModalParams();

    useEffect(() => {
        async function fetchFile() {
            if (params == null) return;

            const file = params.file;
            let src = await readFile(file);

            try {
                const orientation = await getOrientation(file);
                const rotation = ORIENTATION_TO_ANGLE[orientation];
                if (rotation) {
                    src = await getRotatedImage(src, rotation);
                }
            } catch (e) {
                console.warn(e);
            }

            setImageSrc(src);
        }
        void fetchFile();
    }, [params])

    function readFile(file) {
        return new Promise((resolve) => {
            const reader = new FileReader()
            reader.addEventListener('load', () => resolve(reader.result), false)
            reader.readAsDataURL(file)
        })
    }

    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const onSave = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
              imageSrc,
              croppedAreaPixels,
              rotation,
              params.width,
              params.height,
            );

            setCropperResult(croppedImage);
        } catch (e) {
            console.error(e)
        }

        void routeNavigator.hideModal();
    }, [croppedAreaPixels, rotation])

    return <ModalPage
      header={<ModalPageHeader>Редактирование</ModalPageHeader>}
      id={props.id}
    >
        <div
          style={{
              height: 300,
              position: "relative",
              display: "flex",
              padding: 16,
          }}
          id={"croppr_container"}
        >
            {imageSrc &&
                <Cropper
                    image={imageSrc}
                    aspect={params.aspect}
                    cropShape={params.cropShape}
                    crop={crop}
                    rotation={rotation}
                    zoom={zoom}
                    onCropChange={setCrop}
                    // onRotationChange={setRotation}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                />
            }
        </div>
        <Div>
            <Button onClick={onSave} size="l" mode="primary" stretched>Сохранить</Button>
        </Div>
    </ModalPage>
}

CropperModal.propTypes = {
    id: PropTypes.string.isRequired,
}

export default CropperModal