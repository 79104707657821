import PropTypes from "prop-types";
import {
    ActionSheet, ActionSheetItem, AdaptiveIconRenderer,
    CellButton, Div, Header, Text,
    PanelSpinner, Placeholder, Separator, Spacing, Spinner, SplitCol, SplitLayout,
} from "@vkontakte/vkui";
import React, { useEffect, useRef, useState } from "react";
import VKActions from "../../utils/vk_actions";
import EventItem from "components/list/EventItem";
import {Fragment} from 'react';
import ErrorSnack from "components/ErrorSnack";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";
import { useGeneralList } from "api/events/hooks";
import { useInView } from "react-intersection-observer";
import { getEventLink } from "utils/events";
import { Icon20ShareOutline, Icon28ShareOutline } from "@vkontakte/icons";

const dayjs = require('dayjs')
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat)


const AppListTour = (props) => {

    let routeNavigator = useRouteNavigator();
    const [popout, setPopout] = useState(null);
    const itemsRef = useRef([]);

    let dateFrom = dayjs(props.date).format('DD.MM.YYYY');
    const { ref: showMoreRef, inView } = useInView();

    const {
        data,
        isLoading,
        error,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useGeneralList(props.city_id, dateFrom)


    useEffect(() => {
        if (inView) {
            void fetchNextPage();
        }
    }, [inView]);

    let eventsInitial = undefined
    if (data) {
        eventsInitial = data.pages.flatMap((page) => page.data)
    }

    const [events, setEvents] = useState(eventsInitial)

    useEffect(() => {
        if (data) {
            setEvents(data.pages.flatMap((page) => page.data))
        }
    }, [data])


    const openEvent = (id) => {
        void routeNavigator.push(routes.default_view.event, { event: id });
    }

    const shareEvent = function(id) {
        VKActions.share(
          getEventLink(id),
        );
    };


    const openMoreMenu = (event, index) => setPopout(
      <ActionSheet
        onClose={() => setPopout(null)}
        header={event.erid && <Text style={{ maxWidth: 200 }}>{event.erid}</Text>}
        iosCloseItem={
            <ActionSheetItem
              mode="cancel"
            >
                Отменить
            </ActionSheetItem>
        }
        toggleRef={itemsRef.current[index]}
      >
          <ActionSheetItem
            onClick={() => shareEvent(event.id)}
            before={
                <AdaptiveIconRenderer
                  IconCompact={Icon20ShareOutline}
                  IconRegular={Icon28ShareOutline}
                />
            }
          >
              Поделиться
          </ActionSheetItem>
      </ActionSheet>,
    );

    const showPlaceholder = !isLoading
        && events
        && (events.length === 0
            || !props.isActual
            && dayjs(events[0].date, 'YYYY-MM-DD').isAfter(dayjs(props.date)))
    const showAnotherDate = showPlaceholder && events && events.length > 0

    return <SplitLayout popout={popout}>
        <SplitCol>
            {isLoading && <PanelSpinner />}

            {showPlaceholder && <Placeholder>
                В указанную дату
                <br />
                мероприятий в выбранном городе не найдено :(
            </Placeholder>}

            {showAnotherDate && <Header mode="tertiary">Мероприятия в другие даты</Header>}
            {events && events.map((event) => <Fragment key={event.id}>
                <Separator />
                <Spacing />
                <EventItem
                  showBadgeSelector={false}
                  event={event}
                  openEvent={openEvent}
                  manageRef={el => itemsRef.current[event.id] = el}
                  openManage={(id) => openMoreMenu(event, id)}
                />
                <Spacing />

            </Fragment>)}
            {isFetchingNextPage && <>
                <Separator />
                <Div>
                    <Spinner
                      size="small"
                      style={{ margin: "20px 0" }}
                    />
                </Div>
            </>}
            {hasNextPage && !isFetchingNextPage && <>
                <Separator />
                <Div
                  getRootRef={showMoreRef}
                >
                    <CellButton
                      onClick={fetchNextPage}
                      centered
                    >
                        Показать еще
                    </CellButton>
                </Div>
            </>}

            {error && <ErrorSnack
              onClose={() => setSnackbar(null)}
              reason={error}
            />}
        </SplitCol>
    </SplitLayout>;
}

AppListTour.propTypes = {
    city_id: PropTypes.number.isRequired,
    date: PropTypes.object.isRequired,
    isActual: PropTypes.bool.isRequired,
};

export default AppListTour