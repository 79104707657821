import {
    Button,
    ButtonGroup, Calendar, Div, PanelSpinner, Spacing,
    SplitCol,
    SplitLayout,
    Popover,
} from "@vkontakte/vkui";
import React, { useState } from "react";
import { useIsSmallScreen } from "hooks/adaptivity";
import { Icon16ArrowTriangleDown } from "@vkontakte/icons";
import CitySelect from "components/cityselect/CitySelect";
import AppListTour from "components/list/AppList";
import { logScreen } from "utils/analytics";
import useUserCity, { setSelectedCity } from "../hooks/userCity";
import { useAnalytics } from "hooks/useAnalytics";
import bridge from "@vkontakte/vk-bridge";
import { useSearchParams } from "@vkontakte/vk-mini-apps-router";

const dayjs = require('dayjs')

const AppSchedule = () => {

    // Init ads
    bridge.send("VKWebAppShowBannerAd");

    const [params, setParams] = useSearchParams();

    let dateParam = params.get("date");

    let defaultDate = new Date();
    let defaultDateTitle = "Дата";

    if (dateParam) {
        defaultDateTitle = dateParam
        defaultDate = dayjs(dateParam, 'DD.MM.YY').toDate();
    }

    const [date, setDate] = useState(defaultDate);
    const [dateFormatted, setDateFormatted] = useState(defaultDateTitle);
    const userCity = useUserCity()

    const smallScreen = useIsSmallScreen();
    const buttonSize = smallScreen ? 'm' : 'l'

    function showActual() {
        setDate(new Date())
        setDateFormatted("Дата")
        setParams({});
    }

    function showByDate(date) {
        setDate(date)
        let dateFormatted = dayjs(date).format('DD.MM.YY');
        setDateFormatted(dateFormatted)
        setParams({ date: dateFormatted });
    }

    useAnalytics(() => {
        logScreen("app_schedule")
    })

    let loading = userCity.cityLoading;
    let selectedCityId = userCity.city.selectedCity;

    return (<>

        {loading && <PanelSpinner style={{minHeight: 500}}/>}

        {!loading && <Div>

            {smallScreen &&
                <>
                    <Button href={"https://vk.com/@dvizh_app-music-afisha"} target={"_blank"} mode={"tertiary"}
                            stretched={true} size={"s"}>
                        Добавить свое мероприятие
                    </Button>
                    <Spacing size={16}/>
                </>
            }

            {smallScreen &&
                <>
                    <CitySelect onSelect={(id) => setSelectedCity(id)} defaultCity={userCity.city}
                                selectedCity={selectedCityId}/>
                    <Spacing size={16}/>
                </>
            }

            <SplitLayout>
                {!smallScreen && <SplitCol width={300} style={{marginRight: 36}}>
                    <CitySelect onSelect={(id) => setSelectedCity(id)} defaultCity={userCity.city}
                                selectedCity={selectedCityId}/>
                </SplitCol>}
                <SplitCol width={"100%"}>
                    <ButtonGroup stretched={smallScreen}>
                        <Button size={buttonSize} stretched={smallScreen}
                                appearance={"neutral"} onClick={showActual}
                                mode={!dateParam ? "secondary" : "outline"}
                        >Актуальные</Button>

                        <Popover
                            content={<Calendar disablePast={true} value={date} onChange={showByDate}
                                               size={buttonSize}/>}>
                            <Button size={buttonSize} stretched={smallScreen} appearance={"neutral"}
                                    mode={!!dateParam ? "secondary" : "outline"}
                                    before={<Icon16ArrowTriangleDown/>}>{dateFormatted}</Button>
                        </Popover>
                    </ButtonGroup>
                </SplitCol>
                {!smallScreen && <SplitCol width={400}>
                    <Button href={"https://vk.com/@dvizh_app-music-afisha"} target={"_blank"} appearance={"neutral"}
                            mode={"tertiary"} size={"l"}>
                        Добавить свое мероприятие
                    </Button>
                </SplitCol>}
            </SplitLayout>

        </Div>}


        {!!selectedCityId && <AppListTour
          isActual={!dateParam}
          date={date}
          city_id={selectedCityId}
        />}
    </>)
}

export default AppSchedule