import {
  QueryClient,
} from "react-query";
import VKActions from "utils/vk_actions";

const MAX_RETRIES = 2;
const HTTP_STATUS_TO_NOT_RETRY = [400, 401, 403, 404];

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: () => {
      },
    },
    queries: {
      onError: () => {
      },
      onSettled: () => {
        setTimeout(() => {
          VKActions.init();
        }, 100);
      },
      select: (data) => data.data,
      getNextPageParam: lastPage => lastPage?.links?.next ?? undefined,
      refetchOnWindowFocus: false,
      retry: (failureCount, error) => {
        if (failureCount > MAX_RETRIES) {
          return false;
        }

        if (
          // isAxiosError(error) && TODO: update and uncomment
          HTTP_STATUS_TO_NOT_RETRY.includes(error.response?.status ?? 0)) {
          console.log(`Aborting retry due to ${error.response?.status} status`);
          return false;
        }

        return true;
      },
    },
  },
});

export default queryClient