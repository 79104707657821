import {CustomSelect} from "@vkontakte/vkui";
import React, {useEffect, useState} from "react";
import {http} from "api/axiosConfig";
import PropTypes from "prop-types";
import {useQuery} from "react-query";

const CitySelect = (props) => {

    const [selected, setSelected] = useState(props.selectedCity)
    const [items, setItems] = useState([{
        label: props.defaultCity.city,
        value: props.defaultCity.vk_city_id
    }])

    const filterCities = (inputValue, option) => {
        return option.label.toLowerCase().includes(inputValue.toLowerCase())
    }

    const {data, isFetching} = useQuery(
        "cities",
        () => http.get('/utils/cities').then((res) => res.data),
        {staleTime: 4 * 60 * 60 * 1000,}
    );

    useEffect(() => {
        if (data) {
            setItems(data.map((item) => ({
                label: item.city,
                value: item.vk_city_id
            })))
        }
    }, [data])

    function onSelect(e) {
        let id = e.target.value;
        setSelected(id)
        props.onSelect(id)
    }

    return <CustomSelect
        placeholder={"Выберите город"}
        value={selected}
        onChange={onSelect}
        searchable
        required={true}
        forceDropdownPortal
        filterFn={filterCities}
        options={items}
        fetching={isFetching}
    />
}

CitySelect.propTypes = {
    defaultCity: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    selectedCity: PropTypes.number,
}

export default CitySelect