import React from "react";
import {Text} from "@vkontakte/vkui";

const OneLineText = (props) => {
    return <Text
      {...props}
      level="2"
      style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
    >{props.children}</Text>;
};

export default OneLineText