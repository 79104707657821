import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { EVENT_MODALS } from "routes";

export function useShareLinkModal() {
  let routeNavigator = useRouteNavigator();

  return {
    show: () => {
      void routeNavigator.showModal(EVENT_MODALS.SHARE_LINK);
    },
  };
}