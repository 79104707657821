import {Div, ModalCard} from "@vkontakte/vkui";
import React, {useEffect, useState} from "react";
import {http} from "api/axiosConfig";

const AboutPremiumDialog = (props) => {

    const [premium, setPremium] = useState(null)

    useEffect(() => {
        http.post('/frame/premium/').then((json) => {
            let premiumState = json.data.data
            setPremium(Object.assign({}, premium ?? {}, premiumState))
        })
    }, [])

    return <ModalCard nav={props.nav}>
        <Div>
            <ul className={"description"}>

                <li>
                    Первый месяц бесплатно. Данные банковской карты указывать не нужно.
                </li>
                <li>
                    После окончания бесплатного периода весь функционал вернется к стандартным настройкам. Чтобы
                    продолжить пользоваться премиум-аккаунтом необходимо будет его оплатить.
                </li>
                <li>
                    Оплаченный период начнется сразу после окончания бесплатного периода.
                </li>
                <li>
                    Оплата не производится автоматически.
                </li>
                {premium && <li>
                    Стоимость премиум-аккаунта после бесплатного периода:
                    {' '}
                    <b>{premium.payments.map((value, i) => value.description).join(" или ")}</b>
                    {' '}на выбор.
                </li>}
            </ul>
        </Div>
    </ModalCard>
}

export default AboutPremiumDialog