import { useGroupId } from "hooks/params";
import bridge from "@vkontakte/vk-bridge";

export const item_3month = {
    item_id: "3months",
    item_name: "3months",
    item_category: "subscription",
    price: 597,
};

export const item_6month = {
    item_id: "6months",
    item_name: "6months",
    item_category: "subscription",
    price: 894,
};

let groupId = -1;

function logEvent(event, id) {
    bridge
      .send("VKWebAppTrackEvent", {
          event_name: event,
          user_id: id,
      })
      .catch((error) => {
          console.log(error);
      });
}

export function initAnalytics() {
    groupId = useGroupId();
}

export function logScreen(name) {
    console.info("logScreen", name);
    logEvent("screen_app_schedule", "");
}

export function logTipViewByGroup() {
    logEvent(`tip_view`, groupId);
}

export function logPremiumTipViewByGroup() {
    logEvent(`premium_tip_view`, groupId);
}

export function logScreenByGroup(name) {
    console.info("logScreenByGroup", name, groupId);
    logEvent(`screen_${name}`, groupId);
}

export function logEventFormScreen(isCopy, isEdit) {
    console.info("logEventFormScreen", isCopy, isEdit);

    if (isCopy) {
        logEvent(`screen_copy_event`, groupId);
    } else if (isEdit) {
        logEvent(`screen_edit_event`, groupId);
    } else {
        logEvent(`screen_create_event`, groupId);
    }
}

export function logEventScreen(id) {
    console.info("logEvent", id, groupId);
    logEvent("screen_event", groupId);
}

export function beginCheckout(product) {
    console.info("beginCheckout", product, groupId);

    logEvent("begin_checkout", groupId);
}

export function purchase(product, id) {
    console.info("purchase", product, groupId);

    logEvent("purchase", groupId);
}