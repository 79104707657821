import { Avatar } from "@vkontakte/vkui";
import { Icon28CameraOutline, Icon28DeleteOutline } from "@vkontakte/icons";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useCropperModal, useCropperResult, useDispatchCropperResult } from "hooks/useCropperModal";

const ImageInput = (props) => {

    let [imageSrc, setImageSrc] = useState(props.src)

    let { remove: removeCropperCache } = useDispatchCropperResult(props.modalKey);

    useEffect(() => {
        removeCropperCache();
    }, []);

    let { show: showCropperModal } = useCropperModal();
    let { result: cropperResult } = useCropperResult(props.modalKey);

    useEffect(() => {
        setImageSrc(cropperResult ? cropperResult : props.src);
    }, [cropperResult, props.src])

    const deleteImage = () => {
        let result = {
            image_present: 0
        }
        setImageSrc(null)
        props.onChange(result)
        removeCropperCache();
    }

    const imageChanged = (base64) => {
        if (!base64) return
        let result = {
            image_present: !!base64 ? 1 : 0,
            image_base64: base64
        }
        props.onChange(result)
    }

    /**
     * @param file {File}
     */
    function openPopout(file) {
        showCropperModal(props.modalKey, {
            file: file,
            cropShape: "round",
            width: 200,
            height: 200,
            aspect: 1,
        });
    }

    const onImageInputValueChanged = (e) => {
        let file = Array.from(e.target.files)[0];
        e.target.value = null
        openPopout(file);
    }

    useEffect(() => {
        imageChanged(cropperResult);
    }, [cropperResult])

    return (<>
        {imageSrc &&
          <Avatar
            size={128}
            src={imageSrc}
            onClick={deleteImage}
          >
              <Avatar.Overlay>
                  <Icon28DeleteOutline
                    width={48}
                    height={48}
                  />
              </Avatar.Overlay>
          </Avatar>
        }
        {!imageSrc &&
          <>
              <input
                onChange={onImageInputValueChanged}
                type="file"
                id="fileInput"
                style={{ display: "none" }}
                accept="image/jpeg, image/png"
              />
              <label
                htmlFor="fileInput"
                style={{ display: "inline-block" }}
              >
                <Avatar
                  style={{ background: "var(--vkui--color_background_secondary)" }}
                  size={128}
                >
                    <Icon28CameraOutline
                      width={48}
                      height={48}
                    />
                </Avatar>
            </label>
          </>
        }
    </>);
}

ImageInput.propTypes = {
    src: PropTypes.string,
    onChange: PropTypes.func.isRequired,
    modalKey: PropTypes.string.isRequired,
}

export default ImageInput