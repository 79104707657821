import "@vkontakte/vkui/dist/vkui.css";
import "./App.css";
import React from "react";
import {
  useActiveVkuiLocation,
  useGetPanelForView,
  usePopout,
  useRouteNavigator,
} from "@vkontakte/vk-mini-apps-router";
import { Epic, ModalRoot, Root, SplitCol, SplitLayout, View } from "@vkontakte/vkui";
import {
  COMMON_MODALS,
  DEFAULT_ROOT,
  DEFAULT_VIEW,
  DEFAULT_VIEW_PANELS,
  EVENT_MODALS, PREMIUM_MODALS,
  TAGS_MODALS, WIDGET_MODALS,
} from "routes";
import FirstLevelLayout from "components/layouts/FirstLevelLayout";
import EmailForm from "components/EmailForm";
import AboutPremiumDialog from "components/AboutPremiumDialog";
import Resolver from "pages/Resolver";
import Event from "pages/Event";
import EventLinks from "pages/modal/EventLinks";
import SecondLevelLayout from "components/layouts/SecondLevelLayout";
import TagsForm from "components/tags/TagsForm";
import CropperModal from "components/ImageInput/CropperModal";
import EventForm from "pages/EventForm/EventForm";
import AppSchedule from "pages/AppSchedule";
import GroupAppRoot from "pages/Group/GroupAppRoot";
import WidgetAdminOnlyModal from "components/widget/WidgetAdminOnlyModal";
import BadgeSelector from "pages/modal/BadgeSelector";

const App = () => {

  const routerPopout = usePopout();
  const routeNavigator = useRouteNavigator();

  const {
    root: activeRoot = DEFAULT_ROOT,
    view: activeView = DEFAULT_VIEW,
    modal: activeModal,
  } = useActiveVkuiLocation();

  const defaultActivePanel = useGetPanelForView(activeView);

  const modal = (
    <ModalRoot
      activeModal={activeModal}
      onClose={() => routeNavigator.hideModal()}
    >
      <EmailForm nav={PREMIUM_MODALS.EMAIL} />
      <WidgetAdminOnlyModal nav={WIDGET_MODALS.ADMIN_ONLY} />
      <AboutPremiumDialog nav={PREMIUM_MODALS.ABOUT} />
      <TagsForm nav={TAGS_MODALS.CREATE} />
      <TagsForm nav={TAGS_MODALS.EDIT} />
      <EventLinks id={EVENT_MODALS.SHARE_LINK} />
      <BadgeSelector id={COMMON_MODALS.BADGE_SELECTOR} />
      <CropperModal id={COMMON_MODALS.CROP_IMAGE} />
    </ModalRoot>
  );

  return (
    <SplitLayout
      popout={routerPopout}
      modal={modal}
    >
      <SplitCol>
        <Epic
          activeStory={activeRoot}
        >

          <Root
            activeView={activeView}
            nav={DEFAULT_ROOT}
          >
            <View
              nav={DEFAULT_VIEW}
              activePanel={defaultActivePanel}
            >

              <Resolver nav={DEFAULT_VIEW_PANELS.RESOLVER} />

              <FirstLevelLayout
                nav={DEFAULT_VIEW_PANELS.GROUP_APP}
                children={<GroupAppRoot />}
              />

              <FirstLevelLayout
                nav={DEFAULT_VIEW_PANELS.MAIN_APP}
                children={<AppSchedule />}
              />

              <SecondLevelLayout
                nav={DEFAULT_VIEW_PANELS.ADD_EVENT}
                children={<EventForm />}
              />

              <SecondLevelLayout
                nav={DEFAULT_VIEW_PANELS.EDIT_EVENT}
                children={<EventForm isEdit={true} />}
              />

              <SecondLevelLayout
                nav={DEFAULT_VIEW_PANELS.COPY_EVENT}
                children={<EventForm isCopy={true} />}
              />

              <SecondLevelLayout
                nav={DEFAULT_VIEW_PANELS.EVENT}
                children={<Event />}
              />
            </View>

          </Root>
        </Epic>
      </SplitCol>
    </SplitLayout>
  );
};

export default App;