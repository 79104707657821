import React, { useEffect, useMemo, useState } from "react";
import { CustomSelect, CustomSelectOption } from "@vkontakte/vkui";
import PropTypes from "prop-types";
import { useDebounce } from "hooks/useDebounce";
import { useSearchVkCities } from "api/utils/hooks";

export const NO_CITY = -1;
export const CUSTOM_CITY = 0;

const VkCitySelect = (props) => {

  const { selected, onSelect, token, ...selectProps } = props;

  const [query, setQuery] = useState("");
  const debouncedQuery = useDebounce(query, 200);

  let { data: cities, isLoading: loading } = useSearchVkCities(debouncedQuery, token);

  const items = useMemo(() => handleSuggestions(cities ?? [], selected), [cities, query, selected]);

  useEffect(() => {
    setQuery("");
  }, [selected]);


  function handleSuggestions(cities, selected) {
    if (query.length === 0 && selected) {
      return [selected];
    }
    if (query.length > 0 && cities.length === 0) {
      return [
        {
          label: query,
          value: CUSTOM_CITY,
        },
      ];
    }
    return cities
      .sort((a, b) => a.id - b.id)
      .map((item) => {
        let descArray = [];

        if (item.country) {
          descArray.push(item.country);
        }

        if (item.region) {
          descArray.push(item.region);
        }

        if (item.area) {
          descArray.push(item.area);
        }

        return {
          label: item.title,
          value: item.id,
          description: descArray.join(", "),
        };
      });
    }

  function onChange(e) {
    if (!e.target.value) {
      setQuery("");
      onSelect(null);
      return;
    }
    let id = e.target.value;
    let item = items.find(value => parseInt(value.value) === parseInt(id));
    onSelect(item);
  }

  return <CustomSelect
    {...selectProps}
    placeholder={"Выберите город"}
    value={selected?.value}
    onChange={onChange}
    searchable
    allowClearButton={true}
    onInputChange={e => {
      let { value } = e.target;
      setQuery(value);
    }}
    forceDropdownPortal
    options={items}
    fetching={loading}
    filterFn={() => true}
    renderOption={({ option, ...restProps }) => (
      <CustomSelectOption {...restProps}
                          description={option.description}
      >
        {option.value === CUSTOM_CITY ? (<span style={{ color: "var(--vkui--color_background_accent)" }}>
                        {option.label}
                      </span>) : (option.label)}
      </CustomSelectOption>
    )}
  />;
};

VkCitySelect.propTypes = {
  token: PropTypes.string.isRequired,
  onSelect: PropTypes.func,
  selected: PropTypes.exact({
    label: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
  }),
};

export default VkCitySelect;