import {querystring} from "@vkontakte/vkjs";

const params = window.location.search.slice(1);

export const useQueryParams = () => {
    return querystring.parse(params);
};

export const useQueryParamsString = () => {
    return params
}

export const useIsFrame = () => {
    return window !== window.parent;
};

export const useShowHeader = () => {
    let p = useQueryParams()['vk_platform'];
    return !(p === 'desktop_web' || p === 'mobile_web');
};

export const useIsIosClient = () => {
    let p = useQueryParams()['vk_platform'];
    return p === 'mobile_ipad' || p === 'mobile_iphone' || p === 'mobile_iphone_messenger';
};

export const useGroupId = () => {
    return parseInt(useQueryParams()['vk_group_id'])
}

export const useProfileId = () => {
    return parseInt(useQueryParams()['vk_profile_id'])
}

export const useTS = () => {
    return parseInt(useQueryParams()["vk_ts"]);
};

export const useViewerType = () => {
    return useQueryParams()["vk_viewer_group_role"];
};

export const useUserId = () => {
    return parseInt(useQueryParams()["vk_user_id"]);
};

export const useIsProfileApp = () => {
    return !!useQueryParams()["vk_profile_id"];
};

export const useIsGroupApp = () => {
    return !!useQueryParams()["vk_group_id"];
};

export const useAppId = () => {
    return parseInt(useQueryParams()["vk_app_id"]);
};

export const useAppLink = () => {
    if (useIsCommon()) {
        return "https://vk.com/app" + useAppId();
    } else {
        return "https://vk.com/app" + useAppId() + "_-" + useGroupId();
    }
};

export const useIsCommon = () => {
    return !useIsGroupApp() && !useIsProfileApp()
}

export const useIsAppAdmin = () => {
    if (useIsGroupApp()) {
        return useQueryParams()["vk_viewer_group_role"] === "admin" || useQueryParams()["vk_viewer_group_role"] === "editor";
    }
    if (useIsProfileApp()) {
        return useProfileId() === useUserId();
    }
    return false;
}