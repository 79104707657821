import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { EventsApi } from "api/events/api";
import { useGroupId, useTS } from "hooks/params";
import queryClient from "utils/queryClient";

export const useEvent = (id) => {
  return useQuery(
    ["events", { event: id }],
    () => EventsApi.get(id),
  );
};

export const useGroupList = (type, tag) => {
  let groupId = useGroupId();
  let ts = useTS();
  return useInfiniteQuery(
    ["events", { type: type, tag: tag, ts: ts, groupId: groupId }],
    ({ pageParam: pageUrl }) => pageUrl ? EventsApi.group_list_paginated(pageUrl) : EventsApi.group_list(type, tag),
    {
      select: (data) => data,
    },
  );
};

export const useGeneralList = (cityId, dateFrom) => {
  return useInfiniteQuery(
    ["app_schedule", { cityId: cityId, dateFrom: dateFrom }],
    ({ pageParam: pageUrl }) => pageUrl ? EventsApi.app_list_paginated(dateFrom, pageUrl) : EventsApi.app_list(cityId, dateFrom),
    {
      select: (data) => data,
    },
  );
};

export const useDeleteEvent = () => {
  return useMutation(
    (id) => EventsApi.delete(id),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries("events");
      },
    },
  );
};

export const useSetIconEvent = () => {
  return useMutation(
    ({id, icon_name}) => EventsApi.set_icon_name(id, icon_name),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries("events");
      },
    },
  );
};

export const useEventFormInfo = (id) => {
  return useQuery(
    ["events", "form", { event: id }],
    () => EventsApi.get(id),
    {
      cacheTime: 0,
    },
  );
};
export const usePlaceSuggestions = () => {
  return useQuery(
    ["popular_places"],
    () => EventsApi.place_suggestions(),
  );
};