import PropTypes from "prop-types";
import {
  Icon28AdvertisingCircleFillRed,
  Icon28BookmarkCircleFillYellow,
  Icon28CakeCircleFillRaspberryPink,
  Icon28CalendarCircleFillRed,
  Icon28CheckCircleFillYellow,
  Icon28DiscussionsCircleFillGreen,
  Icon28FavoriteCircleFillYellow,
  Icon28FireCircleFillRed, Icon28GameCircleFillBlue,
  Icon28GiftCircleFillRed,
  Icon28GlobeCircleFillBlue,
  Icon28Hearts2CircleFillTwilight,
  Icon28LightbulbCircleFillYellow,
  Icon28LikeCircleFillRed, Icon28PinCircleFillBlue,
  Icon28PopupStickersCircleFillRaspberryPinkProduct,
  Icon28RoubleCircleFillBlue,
  Icon28SongCircleFillViolet,
  Icon28StarsCircleFillViolet, Icon28Users3CircleFillBlue,
} from "@vkontakte/icons";

const IconBadge = (props) => {
  switch (props.name) {
    case "FireCircleFillRed":
      return <Icon28FireCircleFillRed />;
    case "BookmarkCircleFillYellow":
      return <Icon28BookmarkCircleFillYellow />;
    case  "GlobeCircleFillBlue":
      return <Icon28GlobeCircleFillBlue />;
    case  "AdvertisingCircleFillRed":
      return <Icon28AdvertisingCircleFillRed />;
    case "GiftCircleFillRed":
      return <Icon28GiftCircleFillRed />;
    case "FavoriteCircleFillYellow":
      return <Icon28FavoriteCircleFillYellow />;
    case "GameCircleFillBlue":
      return <Icon28GameCircleFillBlue />;
    case "DiscussionsCircleFillGreen":
      return <Icon28DiscussionsCircleFillGreen />;
    case "PinCircleFillBlue":
      return <Icon28PinCircleFillBlue />;
    case "CakeCircleFillRaspberryPink":
      return <Icon28CakeCircleFillRaspberryPink />;
    case "CalendarCircleFillRed":
      return <Icon28CalendarCircleFillRed />;
    case "CheckCircleFillYellow":
      return <Icon28CheckCircleFillYellow />;
    case "LikeCircleFillRed":
      return <Icon28LikeCircleFillRed />;
    case "LightbulbCircleFillYellow":
      return <Icon28LightbulbCircleFillYellow />;
    case "PopupStickersCircleFillRaspberryPinkProduct":
      return <Icon28PopupStickersCircleFillRaspberryPinkProduct />;
    case "RoubleCircleFillBlue":
      return <Icon28RoubleCircleFillBlue />;
    case "StarsCircleFillViolet":
      return <Icon28StarsCircleFillViolet />;
    case "SongCircleFillViolet":
      return <Icon28SongCircleFillViolet />;
    case "Hearts2CircleFillTwilight":
      return <Icon28Hearts2CircleFillTwilight />;
    case "Users3CircleFillBlue":
      return <Icon28Users3CircleFillBlue />;
    default:
      return <Icon28FireCircleFillRed />;
  }
};

IconBadge.propTypes = {
  size: PropTypes.number,
  name: PropTypes.string.isRequired,
};

export default IconBadge;