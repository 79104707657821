import { QueryClientProvider } from "react-query";
import queryClient from "utils/queryClient";
import { AdaptivityProvider, AppRoot, ConfigProvider } from "@vkontakte/vkui";
import React from "react";
import { RouterProvider } from "@vkontakte/vk-mini-apps-router";
import App from "App";
import { router } from "routes";
import { ImageDataProvider } from "components/ImageInput/ImageDataProvider";
import { useIsCommon } from "hooks/params";
import vkBridge, { parseURLSearchParamsForGetLaunchParams } from "@vkontakte/vk-bridge";
import { useAdaptivity, useAppearance, useInsets } from "@vkontakte/vk-bridge-react";
import { transformVKBridgeAdaptivity } from "utils/transformVKBridgeAdaptivity";

export const AppConfig = () => {
  let isCommon = useIsCommon();
  if (!window.location.hash) {
    if (isCommon) {
      window.location.hash = "app_schedule";
    } else {
      window.location.hash = "group_app";
    }
  }
  const vkBridgeAppearance = useAppearance() || undefined;
  const vkBridgeInsets = useInsets() || undefined;
  const adaptivity = transformVKBridgeAdaptivity(useAdaptivity());
  const { vk_platform } = parseURLSearchParamsForGetLaunchParams(window.location.search);

  return (
    <ConfigProvider
      appearance={vkBridgeAppearance}
      platform={vk_platform === "desktop_web" ? "vkcom" : undefined}
      isWebView={vkBridge.isWebView()}
      hasCustomPanelHeaderAfter={true}
    >
      <QueryClientProvider client={queryClient}>
        <AdaptivityProvider {...adaptivity}>
          <AppRoot
            mode="full"
            safeAreaInsets={vkBridgeInsets}
          >
            <RouterProvider router={router}>
              <ImageDataProvider>
                <App />
              </ImageDataProvider>
            </RouterProvider>
          </AppRoot>
        </AdaptivityProvider>
      </QueryClientProvider>
    </ConfigProvider>
  );
};