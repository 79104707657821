/**
 *
 * @param param {string}
 * @param possibleValues {{}}
 * @return {*}
 */
export function getParamOrDefault(param, possibleValues) {
  let values = Object.values(possibleValues);
  if (values.includes(param)) {
    return param;
  }
  return values[0];
}