import React from "react";
import "./CoverImage.css";

const CoverImage = ({ src }) => {
  return <div
    className={"CoverImage"}
    style={{ backgroundImage: `url(${src})` }}
  />;

};

export default CoverImage;