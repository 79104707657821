import React from "react";
import ReactDOM from "react-dom";
import bridge from "@vkontakte/vk-bridge";
import { useAppLink, useGroupId, useQueryParamsString, useUserId, useViewerType } from "hooks/params";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { initAnalytics } from "utils/analytics";
import { AppConfig } from "AppConfig";
import VKActions from "utils/vk_actions";
import { createRoot } from "react-dom/client";

if (typeof String.prototype.replaceAll == "undefined") {
  String.prototype.replaceAll = function(match, replace) {
    return this.replace(new RegExp(match, "g"), () => replace);
  };
}

Sentry.init({
  enabled: false,
  dsn: "https://8ea6394fba3543cba47af28c19db5f96@o256242.ingest.sentry.io/1447915",
  integrations: [new BrowserTracing()],
  release: process.env.REACT_APP_NAME + "@" + process.env.REACT_APP_VERSION,

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
  initialScope: {
    tags: {
      params: useQueryParamsString(),
      group_id: useGroupId(),
      club_id: "club" + useGroupId(),
      viewer_type: useViewerType()
    },
    user: {
      id: useUserId(),
      url: useAppLink()
    }
  }
});

initAnalytics();

// Init VK  Mini App Fallback
VKActions.observeConfigurationChanges()
setTimeout(() => {
  VKActions.init()
}, 1000);


createRoot(document.getElementById('root')).render(<AppConfig />);
