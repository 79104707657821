import request from "api/axiosConfig";

export const UserApi = {

  get: async function() {
    return await request({
      url: `/frame/user?f_subscribers=true`,
      method: "GET",
    });
  },

  post_email: async function(email) {
    return await request({
      url: `/frame/user/email`,
      method: "POST",
      data: {
        email: email,
      },
    });
  },

  widget_toggle: async function(enable) {
    return await request({
      url: `/frame/widget/toggle`,
      method: "POST",
      data: {
        enable: enable,
      },
    });
  },


  subscribers_list: async function() {
    let url = `/frame/subscriptions/subscribers`;
    return await request({
      url: url,
      method: "GET",
    });
  },


  subscribers_list_paginated: async function(pageUrl) {
    return await request({
      url: pageUrl,
      method: "GET",
    });
  },

  payments_list: async function() {
    let url = `/frame/billing`;
    return await request({
      url: url,
      method: "GET",
    });
  },


  payments_list_paginated: async function(pageUrl) {
    return await request({
      url: pageUrl,
      method: "GET",
    });
  },
};