import { CardScroll, ContentCard, FormItem } from "@vkontakte/vkui";
import OneLineText from "components/OneLineText";
import React from "react";
import PropTypes from "prop-types";
import { usePlaceSuggestions } from "api/events/hooks";

const PopularChoice = (props) => {
    let { data: suggestions } = usePlaceSuggestions();

    function selectPlace(country_id, country, city_id, city, place) {
        props.onSelectItem(country_id, country, city_id, city, place);
    }

    return <>
        {suggestions?.common?.length > 0 && <>
            <FormItem
              top="Популярный выбор"
              style={{ paddingBottom: 0 }}
            ></FormItem>
            <CardScroll>
                {suggestions.common.map((item, i) => <ContentCard
                  key={i}
                  hasActive={true}
                  hasHover={true}
                  onClick={() => selectPlace(item.vk_city_id, item.city, item.place)}
                  mode="tint"
                  text={<OneLineText>{item.city}</OneLineText>}
                  caption={<OneLineText className={"EmptyLine"}>{item.place}</OneLineText>}
                />)}
            </CardScroll>
        </>}
    </>;

};

PopularChoice.propTypes = {
    onSelectItem: PropTypes.func,
};

export default PopularChoice;