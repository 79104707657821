import {Button, Div, FormItem, Input, ModalCard} from "@vkontakte/vkui";
import React, {useState} from "react";
import {http} from "api/axiosConfig";
import { userReload, useTags } from "hooks/user";
import { useParams, useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { TAGS_MODALS } from "routes";

const TagsForm = (props) => {

    let routeNavigator = useRouteNavigator();
    const { tag: tagId } = useParams({ modal: TAGS_MODALS.EDIT }) ?? {};
    let tags = useTags();

    const isNew = !tagId

    let initialName = "";

    if (!isNew) {
        const tag = tags.find(value => parseInt(value.id) === parseInt(tagId));
        initialName = tag.title;
    }

    let [title, setTitle] = useState(initialName)


    function handleSubmit(e) {
        e.preventDefault()
        let url = isNew ? 'frame/tags/create' : 'frame/tags/' + tagId;
        http.post(url, {
            title: title,
        })
          .then(() => {
              userReload();
              void routeNavigator.hideModal();
          })
            .catch(reason => {
                console.log(reason)
            })
    }

    let header = isNew ? "Добавить категорию" : "Редактировать";
    return <ModalCard
      header={header}
      nav={props.nav}
    >
        <form onSubmit={handleSubmit}>
            <FormItem>
                <Input
                  defaultValue={title}
                  name={"title"}
                  required={true}
                  onChange={e => setTitle(e.target.value)}
                  placeholder={"Название"}
                  type={"text"}
                  maxLength="40"
                />
            </FormItem>
            <Div>
                <Button
                  stretched={true}
                  size={"l"}
                  type={"submit"}
                >Сохранить</Button>
            </Div>
        </form>
    </ModalCard>
}

export default TagsForm