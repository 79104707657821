import {Avatar, Snackbar} from "@vkontakte/vkui";
import {Icon32ErrorCircle} from "@vkontakte/icons";
import React from "react";
import PropTypes from "prop-types";
import VKActions from "../utils/vk_actions";

const ErrorSnack = (props) => {
    return <Snackbar
        action={props.showCopy ? "Скопировать лог ошибки" : null}
        onActionClick={() => VKActions.copyText(JSON.stringify(props.reason))}
        before={
            <Avatar size={24} style={{background: "var(--vkui--color_background_negative)"}}>
                <Icon32ErrorCircle fill="#fff" width={14} height={14}/>
            </Avatar>
        }
        onClose={props.onClose}>
        {props.reason && <>
            Ошибка: {JSON.stringify(props.reason)}
        </>}

        {!props.reason && <>Ошибка</>}
    </Snackbar>
}

ErrorSnack.propTypes = {
    reason: PropTypes.object,
    showCopy: PropTypes.bool,
    onClose: PropTypes.func.isRequired
}
ErrorSnack.defaultProps = {
    showCopy: true,
};
export default ErrorSnack