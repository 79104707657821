import { SubnavigationBar, SubnavigationButton } from "@vkontakte/vkui";
import { useTags } from "hooks/user";
import { useIsAppAdmin } from "hooks/params";
import { useParams, useRouteNavigator, useSearchParams } from "@vkontakte/vk-mini-apps-router";
import { GROUP_APP_TABS, routes, SCHEDULE_TABS, SETTINGS_TABS } from "routes";
import { getParamOrDefault } from "utils/navigation";

const TagsContent = () => {

    let routeNavigator = useRouteNavigator();
    let { tab } = useParams({ tab: GROUP_APP_TABS.SCHEDULE }) ?? {};

    const activeTab = getParamOrDefault(tab, SCHEDULE_TABS);

    const [params, setParams] = useSearchParams();

    const activeTag = params.get("tag");

    let isAdmin = useIsAppAdmin()

    const tags = useTags();

    function showByTag(id) {
        setParams({ tag: id });
        void routeNavigator.replace(routes.default_view.group_app.schedule, { tab: SCHEDULE_TABS.TAG }, { keepSearchParams: true });
    }

    function showActual() {
        void routeNavigator.replace(routes.default_view.group_app.schedule, { tab: SCHEDULE_TABS.UPCOMING });
    }

    function showPast() {
        void routeNavigator.replace(routes.default_view.group_app.schedule, { tab: SCHEDULE_TABS.PAST });
    }

    function showSettings() {
        void routeNavigator.replace(routes.default_view.group_app.settings, { tab: SETTINGS_TABS.TAGS });
    }

    return <SubnavigationBar mode={"overflow"}>
        <SubnavigationButton
            onClick={showActual}
            textLevel={1}
            size={"m"}
            selected={activeTab === SCHEDULE_TABS.UPCOMING}
            mode={"primary"}
        >
            Актуальные
        </SubnavigationButton>

        {(tags ?? []).map((item) =>
            <SubnavigationButton
                onClick={() => showByTag(item.id)}
                key={item.id}
                textLevel={1}
                size={"m"}
                selected={activeTab === SCHEDULE_TABS.TAG && activeTag === item.id.toString()}
                mode={"primary"}
            >
                {item.title}
            </SubnavigationButton>
        )}
        <SubnavigationButton
            onClick={showPast}
            textLevel={1}
            size={"m"}
            selected={activeTab === SCHEDULE_TABS.PAST}
            mode={"primary"}
        >
            Прошедшие
        </SubnavigationButton>
        {isAdmin &&
            <SubnavigationButton
                onClick={showSettings}
                textLevel={1} size={"m"}>
                Настроить категории
            </SubnavigationButton>
        }
    </SubnavigationBar>

}

export default TagsContent