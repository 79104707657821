import {
  Avatar,
  Button, ButtonGroup, Div, FixedLayout,
  MiniInfoCell, PanelSpinner, Text,
  Title,
} from "@vkontakte/vkui";
import React, { useEffect, useState } from "react";
import { Icon20ClockOutline, Icon20PlaceOutline, Icon24Linked, Icon24Share } from "@vkontakte/icons";
import VKActions from "utils/vk_actions";
import {
  useAppId, useIsAppAdmin, useIsCommon,
} from "hooks/params";
import { useIsSmallScreen } from "hooks/adaptivity";
import { useAnalytics } from "hooks/useAnalytics";
import { logEventScreen } from "utils/analytics";
import ErrorSnack from "components/ErrorSnack";
import * as Sentry from "@sentry/react";
import { useEvent } from "api/events/hooks";
import { useFirstPageCheck, useParams, useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { DEFAULT_VIEW_PANELS, routes } from "routes";
import { useShareLinkModal } from "hooks/useShareLinkModal";
import CoverImage from "components/CoverImage/CoverImage";
import CoverHeader from "components/CoverHeader/CoverHeader";
import { getEventLink } from "utils/events";

const Event = () => {
  const { event: eventId } = useParams({ panel: DEFAULT_VIEW_PANELS.EVENT }) ?? {};

  let routeNavigator = useRouteNavigator();
  let isFirstPage = useFirstPageCheck();

  let { show: showShareLinkModal } = useShareLinkModal();

  useAnalytics(() => {
    logEventScreen(eventId);
  });

  const [snackbar, setSnackbar] = useState(null);
  let isAppAdmin = useIsAppAdmin();

  const smallScreen = useIsSmallScreen();
  const size = smallScreen ? "s" : "l";

  let { data: event, error } = useEvent(eventId);

  useEffect(() => {
    if (error != null) {
      setSnackbar(<ErrorSnack
        onClose={() => setSnackbar(null)}
        reason={error}
      />);
      Sentry.captureException(error);
    }
  }, [error]);

  const shareClick = () => {
    share(event);
  };

  const share = function(item) {
    VKActions.share(
      getEventLink(item.id),
    );
  };

  const showAllEvents = () => {
    if (isFirstPage) {
      void routeNavigator.replace(routes.default_view.resolver);
    } else {
      void routeNavigator.back();
    }
  };

  function getLink() {
    return `https://vk.com/app${useAppId()}_-${event.owner?.vk_id}`;
  }

  return <>
    {snackbar}
    {!event && <PanelSpinner height={500} />}
    {event && <>

      <CoverHeader
        cover={event.cover && <CoverImage src={event.cover} />}
        image={<Avatar
          style={{ background: "white" }}
          size={128}
          src={event.image}
        />}
      >

        <Div>
          <Title
            level="1"
            weight="3"
          >
            {event.title}
          </Title>

        </Div>
        <MiniInfoCell
          before={<Icon20ClockOutline />}
          textWrap="full"
        >{event.date_time}</MiniInfoCell>
        <MiniInfoCell
          before={<Icon20PlaceOutline />}
          textWrap="full"
        >{event.full_place}</MiniInfoCell>

        <Div>
          <ButtonGroup
            stretched={smallScreen}
            mode="horizontal"
            gap="m"
          >
            {event.buttons
              .sort((a, b) => (a.sort < b.sort ? 1 : -1))
              .map((value, index) =>
                <Button
                  key={index}
                  stretched={smallScreen}
                  size={size}
                  appearance="accent"
                  target="_blank"
                  href={value.link}
                >
                  {value.title}
                </Button>,
              )}
            <Button
              onClick={shareClick}
              before={<Icon24Share />}
              size={size}
              appearance="neutral"
              mode="outline"
            >
              {!smallScreen && "Поделиться"}
            </Button>
            {isAppAdmin &&
              <Button
                onClick={showShareLinkModal}
                before={<Icon24Linked />}
                size={size}
                appearance="neutral"
                mode="outline"
              >
              </Button>
            }
          </ButtonGroup>
        </Div>

        <Div>
          <Text
            weight={"regular"}
            style={{ whiteSpace: "pre-wrap", paddingBottom: 60 }}
          >
            {event.description}
          </Text>
        </Div>

      </CoverHeader>


      <FixedLayout
        filled
        vertical="bottom"
      >

        <Div>
          {useIsCommon() &&
            <Button
              mode={"secondary"}
              stretched
              size={"l"}
              href={getLink()}
              target={"_blank"}
            >
              Все мероприятия {event.owner?.name}
            </Button>
          }
          {!useIsCommon() &&
            <Button
              mode={"secondary"}
              stretched
              size={"l"}
              onClick={showAllEvents}
            >
              Все мероприятия {event.owner?.name}
            </Button>
          }
        </Div>

      </FixedLayout>


    </>}
  </>;
};

export default Event;