import React from "react";
import PropTypes from "prop-types";

import "components/CoverHeader/CoverHeader.css";

const CoverHeader = ({ cover, image, children }) => {
  return <div className="CoverHeader">
    {cover}
    <div className="CoverHeader_content">
      {image}
      <div className="CoverHeader_children">
        {children}
      </div>
    </div>
  </div>;

}

CoverHeader.propTypes = {
    image: PropTypes.node.isRequired,
    cover: PropTypes.node,
}

export default CoverHeader