import {
    Button,
    Calendar,
    Caption,
    Card,
    Checkbox,
    DatePicker,
    Div,
    FormItem,
    FormLayoutGroup, FormStatus,
    Input,
    Link,
    NativeSelect,
    PanelSpinner,
    Placeholder, Spacing,
    SplitCol,
    SplitLayout,
    Textarea,
    Popover,
    ChipsSelect,
} from "@vkontakte/vkui";
import React, { useEffect, useState } from "react";
import VKActions from "utils/vk_actions";
import { useIsSmallScreen } from "hooks/adaptivity";
import useToken, { tokenReload } from "hooks/token";
import { Icon16ArrowTriangleDown, Icon56GhostOutline } from "@vkontakte/icons";
import VkCitySelect, { CUSTOM_CITY, NO_CITY } from "components/cityselect/VkCitySelect";
import { handleError, http } from "api/axiosConfig";
import { useIsPremium, userReload, useTags } from "hooks/user";
import ImageInput from "components/ImageInput/ImageInput";
import PropTypes from "prop-types";
import Row from "components/Row";
import HiddenField from "components/HiddenField";
import ErrorSnack from "components/ErrorSnack";
import { isDesktop, isFirefox } from "react-device-detect";
import { useAnalytics } from "hooks/useAnalytics";
import { logEventFormScreen } from "utils/analytics";
import { useQueryClient } from "react-query";
import * as Sentry from "@sentry/react";
import PopularChoice from "components/form/PopularChoice";
import CoverHeader from "components/CoverHeader/CoverHeader";
import CoverInput from "components/ImageInput/CoverInput";
import { useParams, useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { DEFAULT_VIEW_PANELS } from "routes";
import PremiumTooltip from "components/premium/PremiumTooltip";

const dayjs = require('dayjs')
const customParseFormat = require("dayjs/plugin/customParseFormat");
dayjs.extend(customParseFormat)

const EventForm = (props) => {
    let panel = isCopy ? DEFAULT_VIEW_PANELS.COPY_EVENT : DEFAULT_VIEW_PANELS.EDIT_EVENT;
    const { event: eventId } = useParams({ panel: panel }) ?? {};

    const isEdit = props.isEdit
    const isCopy = props.isCopy

    useAnalytics(() => {
        logEventFormScreen(isCopy, isEdit)
    })

    const [snackbar, setSnackbar] = useState(null)

    const [errors, setErrors] = useState({})

    const [showLoader, setShowLoader] = useState(!!eventId);
    const [showEnd, setShowEnd] = useState(false)
    const [dateChanged, setDateChanged] = useState(false)
    const [updateFlag, setUpdateFlag] = useState(0)
    const token = useToken()
    const isPremium = useIsPremium()
    const queryClient = useQueryClient()

    let routeNavigator = useRouteNavigator();

    const [selectedTags, setSelectedTags] = React.useState([]);

    let [form, setForm] = useState({
        tickets_button_title: "Билеты",
        event_button_title: "Встреча",
        date: dayjs().add(1, "day").format("DD.MM.YYYY"),
    });

    let selectedCity = form.city ? {
        label: form.city,
        value: form.city_id ?? CUSTOM_CITY,
    } : null;

    useEffect(() => {
        if (!eventId) return;

        http.get("frame/schedule/info/" + eventId)
            .then(value => {
                const {date, notify_sent, notify_date, tags, ...data} = value.data.data;
                console.log(value)
                console.log(notify_sent)
                if (tags != null) {
                    setSelectedTags(tags.map((item) => {
                        return {value: item.id, label: item.title}
                    }))
                }
                setForm({
                    date: date ?? form.date,
                    notify_sent: isCopy ? 0 : notify_sent,
                    notify_date: isCopy ? undefined : notify_date,
                    ...data
                })
                setShowEnd(!!data.finish_date)
                setDateChanged(true)
                setShowLoader(false)
            }).catch(reason => {
            Sentry.captureException(reason)
            console.log(reason)
        })

    }, [eventId])

    function daysBeforeEvent() {
        if (form.date != null) {
            return dayjs(form.date, "DD.MM.YYYY").diff(dayjs(new Date().setHours(0, 0, 0, 0)), "days");
        }
        return null
    }

    function shouldDisableNotifyDate(date) {
        if (form.date != null) {
            return dayjs(date).isBefore(new Date(), "day") || dayjs(form.date, "DD.MM.YYYY").isBefore(dayjs(date));
        }
        return true
    }

    let smallScreen = useIsSmallScreen();
    const mainWidth = smallScreen ? "100%" : "60%"

    function setNewFieldValue(name, value) {
        let newValue = {...form, [name]: value};
        setForm(newValue)
        console.log("setNewFieldValue", newValue)
        checkAndChangeTime(newValue)
    }

    function loadEventByLink(event) {
        let link = event.target.value;
        VKActions.loadEvent(link, function (event) {
            let newValue = {...event, ...form};
            if (!dateChanged) {
                newValue['date'] = event['date']
            }
            newValue['event_link'] = link
            console.log(newValue)
            setForm(newValue)
            setShowEnd(!!newValue.finish_date)
            setUpdateFlag(updateFlag + 1)
        })
    }

    function handleInputChange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        setNewFieldValue(name, value);
        if (name === 'event_link') {
            loadEventByLink(event);
        }
    }

    function handleDateChange(name, date) {
        console.log("date", date)
        if (name === 'date') {
            setDateChanged(true)
        }
        if (date.day === 0 || date.month === 0 || date.year === 0) return
        const dateString = `${date.day}.${date.month}.${date.year}`
        let dateMoment = dayjs(dateString, 'D.M.YYYY');
        if (name === 'finish_date' && dayjs(form.date, 'DD.MM.YYYY').isAfter(dateMoment) || name === 'date' && form.finish_date !== null && dateMoment.isAfter(dayjs(form.finish_date, 'DD.MM.YYYY'))) {
            setError('finish_date', 'Неверная дата окончания')
        } else {
            setError('finish_date', null)
        }
        const s = dateMoment.format('DD.MM.YYYY');
        setNewFieldValue(name, s);
    }

    function handleImageChange(data) {
        console.log('handleImageChange', data)
        let newValue = {...form, image: null, ...data};
        setForm(newValue)
    }

    function handleCoverChange(data) {
        console.log('handleCoverChange', data)
        let newValue = {...form, cover: null, ...data};
        setForm(newValue)
    }

    function finishDateRemove() {
        let newValue = {...form, ['finish_date']: null, ['finish_time']: null,};
        setForm(newValue)
        setShowEnd(false)
        checkAndChangeTime(newValue)
    }

    function finishDateShow() {
        let newValue = {...form, ['finish_date']: form["date"], ['finish_time']: null,};
        setForm(newValue)
        setShowEnd(true)
        checkAndChangeTime(newValue)
    }

    function selectPlace(city_id, city, place) {
        let newValue = {
            ...form,
            ['city']: city,
            ['city_id']: parseInt(city_id),
            ['place']: place,
        };
        console.log(newValue)
        setForm(newValue)
    }

    function onSelectCity(city) {
        let newValue = {
            ...form,
            ["city"]: city?.label,
            ["city_id"]: city ? parseInt(city.value) : NO_CITY,
        };
        setForm(newValue)
    }

    function handleNotifyDateChange(date) {
        setError('notify_date', null)
        const s = dayjs(date).format('DD.MM.YYYY');
        setNewFieldValue('notify_date', s)
    }

    function checkAndChangeTime(form) {
        console.log(form.date && form.date === form.finish_date && form.time && form.finish_time)
        if (form.date && form.date === form.finish_date && form.time && form.finish_time) {
            const wrongTime = dayjs(form.time, 'HH:mm').isAfter(dayjs(form.finish_time, 'HH:mm'));
            console.log(wrongTime)
            setError('finish_time', wrongTime ? 'Неверное время окончания' : null)
        } else {
            setError('finish_time', null)
        }
    }

    function handleSubmit(e) {
        e.preventDefault()
        setShowLoader(true)

        const url = isEdit
            ? 'frame/schedule/edit/' + form.id
            : 'frame/schedule/create/'

        if (!e.target.reportValidity()) return

        const tags = selectedTags.map((item) => {
            if (!Number.isInteger(item.value)) {
                return {id: 0, title: item.label}
            }
            return {id: item.value, title: item.label}
        })

        http.post(url, {...form, tags: tags}).then(value => {
            if (value.data.data) {
                userReload()
                queryClient.invalidateQueries("events");
                void routeNavigator.back();
            } else {
                setSnackbar(<ErrorSnack onClose={() => {
                    setSnackbar(null)
                }} reason={"Ошибка при сохранении"}/>)
                setShowLoader(false)
            }
            console.log(value)
        }).catch(reason => {
            let size = -1
            if (form.image_base64) {
                let sizeOfImage = new Blob([form.image_base64]).size;
                size = parseInt(sizeOfImage / 1024);
            }
            let handledError = handleError(reason);
            Sentry.captureException(reason, scope => {
                scope.setExtra("img_size", size)
            })
            console.log(handledError)
            setShowLoader(false)
            setSnackbar(<ErrorSnack onClose={() => {
                setSnackbar(null)
            }} reason={handledError}/>)
        })
    }

    function setError(name, error) {
        setErrors({...errors, [name]: error})
    }

    const tooltip = <PremiumTooltip/>

    const startDate = form.date ? dayjs(form.date, 'DD.MM.YYYY') : dayjs().add(1, 'day');

    const startDateInput = {day: startDate.date(), month: startDate.month() + 1, year: startDate.year()}

    const finishDate = dayjs(form.finish_date, 'DD.MM.YYYY');
    const finishDateInput = form.finish_date ? {
        day: finishDate.date(), month: finishDate.month() + 1, year: finishDate.year()
    } : null

    const showNotificationForm = parseInt(form.notify_days ?? -1) !== -1 || !!form.notify_date

    const notifyDate = form.notify_date ? dayjs(form.notify_date, 'DD.MM.YYYY').toDate() : new Date();

    const showTimeInput = !(isFirefox && isDesktop)

    const tagsProps = useTags()?.map((item) => {
        return {
            value: item.id,
            label: item.title,
        }
    }) || []

    const tagsChipsProps = {
        value: selectedTags,
        onChange: setSelectedTags,
        options: tagsProps,
        placeholder: "Не выбраны",
        creatable: true,
        creatableText: "Создать категорию"
    };

    return <>
        {token.error && <Div>
            <Placeholder
                icon={<Icon56GhostOutline/>}
                header={"Разрешите доступ, чтобы создать мероприятие"}
                action={<Button size="l" onClick={() => tokenReload()}>Разрешить</Button>}
            >
                Это нужно для доступа приложения к списку стран и городов VK
            </Placeholder>
        </Div>}
        {token.token && showLoader && <PanelSpinner height={800}/>}
        {token.token && !showLoader && <SplitLayout>
            <SplitCol width={mainWidth}>
                <form onSubmit={handleSubmit}>

                    <FormItem>
                        <CoverHeader
                          cover={<CoverInput
                            onChange={handleCoverChange}
                            modalKey={"event_cover"}
                            src={form.cover}
                          />
                            }
                            image={
                                <ImageInput
                                  onChange={handleImageChange}
                                  src={form.image}
                                  modalKey={"event_image"}
                                />
                            }>
                        </CoverHeader>
                    </FormItem>
                    <FormItem top="Название события*">
                        <Input defaultValue={form.title} name={"title"}
                               required={true}
                               maxLength="200"
                               onChange={handleInputChange}/>
                    </FormItem>

                    <FormItem top="Категория мероприятия">
                        {!isPremium &&
                            <Input defaultValue={''}
                                          placeholder="Выбрать категории"
                                          disabled={true}
                                          after={tooltip}/>
                        }
                        {isPremium &&
                            <ChipsSelect {...tagsChipsProps} />
                        }
                    </FormItem>

                    <FormItem>
                        <Checkbox defaultChecked={form.is_open_date} name={"is_open_date"}
                                  disabled={!form.is_open_date && !isPremium}
                                  onChange={handleInputChange}>
                            <Row after={!isPremium && tooltip}>Открытая дата</Row>
                        </Checkbox>
                    </FormItem>


                    {!!errors.finish_time && <FormItem>
                        <FormStatus mode="error">
                            Время окончания должно быть больше времени начала
                        </FormStatus>
                    </FormItem>}

                    {!form.is_open_date && <><FormLayoutGroup mode={"horizontal"}>
                        <FormItem top="Дата начала*" style={{flexGrow: smallScreen ? 1 : 2}}>
                            <DatePicker
                                required={true}
                                key={updateFlag + "start_date" + form.id}
                                min={{day: 1, month: 1, year: 2021}}
                                max={{day: 31, month: 12, year: new Date().getFullYear() + 2}}
                                defaultValue={startDateInput}
                                name={"date"}
                                onDateChange={(value) => {
                                    handleDateChange('date', value)
                                }}
                                dayPlaceholder="ДД"
                                monthPlaceholder="ММММ"
                                yearPlaceholder="ГГГГ"
                            />
                        </FormItem>
                        <FormItem top="Время начала"
                                  status={errors.finish_time ? "error" : "default"}>
                            {showTimeInput &&
                                <Input type="time" defaultValue={form.time ?? ""} name={"time"}
                                       key={updateFlag + "start_time" + form.id}
                                       onChange={handleInputChange}/>}
                            {!showTimeInput &&
                                <Input type="text" defaultValue={form.time ?? ""} name={"time"}
                                       placeholder={"--:--"}
                                       key={updateFlag + "start_time_text" + form.id}
                                       pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}"
                                       onChange={handleInputChange}/>}
                        </FormItem>
                    </FormLayoutGroup>

                        {!showEnd && <Div>
                            <Link onClick={finishDateShow}>
                                <Caption
                                    style={{borderBottom: "1px dashed var(--accent)"}}>
                                    Указать дату и время окончания
                                </Caption>
                            </Link>
                        </Div>}

                        {showEnd && <FormLayoutGroup mode={"horizontal"} removable={true} onRemove={finishDateRemove}>
                            <FormItem top="Дата окончания" style={{flexGrow: smallScreen ? 1 : 2}}
                                      status={errors.finish_date ? "error" : "default"}
                                      bottom={errors.finish_date}>
                                <DatePicker
                                    disabled={!form.date}
                                    key={updateFlag + "finish_date" + form.id + showEnd}
                                    min={startDateInput}
                                    max={{day: 31, month: 12, year: new Date().getFullYear() + 2}}
                                    defaultValue={finishDateInput}
                                    onDateChange={(value) => {
                                        handleDateChange('finish_date', value)
                                    }}
                                    dayPlaceholder="ДД"
                                    monthPlaceholder="ММММ"
                                    yearPlaceholder="ГГГГ"
                                />
                            </FormItem>
                            <FormItem top="Время окончания"
                                      status={errors.finish_time ? "error" : "default"}
                                      bottom={errors.finish_date ? '\u00A0' : ""}>
                                {showTimeInput &&
                                    <Input type="time" defaultValue={form.finish_time ?? ""} name={"finish_time"}
                                           key={updateFlag + "finish_time" + form.id}
                                           onChange={handleInputChange}/>
                                }
                                {!showTimeInput &&
                                    <Input type="text" defaultValue={form.finish_time ?? ""} name={"finish_time"}
                                           key={updateFlag + "finish_time_text" + form.id}
                                           placeholder={"--:--"}
                                           pattern="([01]?[0-9]{1}|2[0-3]{1}):[0-5]{1}[0-9]{1}"
                                           onChange={handleInputChange}/>
                                }
                            </FormItem>
                        </FormLayoutGroup>}
                    </>}

                    <FormItem>
                        <Checkbox defaultChecked={form.is_online} name={"is_online"}
                                  onChange={handleInputChange}>
                            Онлайн мероприятие
                        </Checkbox>
                    </FormItem>

                    {!form.is_online && <>

                        {!isEdit && !isCopy && <PopularChoice
                          onSelectItem={(city_id, city, place) => selectPlace(city_id, city, place)}
                        />
                        }
                        <FormItem top="Город*">
                            <VkCitySelect
                              token={token.token}
                              required={true}
                              selected={selectedCity}
                              maxLength="200"
                              onSelect={onSelectCity}
                            />
                            <HiddenField
                              name={"city"}
                              defaultValue={form.city ?? ""}
                            />
                        </FormItem>
                        <FormItem top="Место">
                            <Input defaultValue={form.place} name={"place"}
                                   maxLength="200"
                                   key={updateFlag + " " + form.city + "place" + form.id}
                                   onChange={handleInputChange}/>
                        </FormItem>
                    </>}

                    <FormItem top="Описание мероприятия">
                        <Textarea maxLength={10000} key={updateFlag + " " + 'Textarea' + form.id}
                                  defaultValue={form.description ?? ""}
                                  name={"description"}
                                  onChange={handleInputChange}/>
                    </FormItem>


                    <FormItem>
                        <Checkbox defaultChecked={form.priority}
                                  description={isPremium && "мероприятие всегда находится вверху Афиши"}
                                  name={"priority"}
                                  disabled={!isPremium}
                                  onChange={handleInputChange}>
                            <Row after={!isPremium && tooltip}>Закрепить</Row>
                        </Checkbox>
                    </FormItem>

                    <Div>

                        {!!form.notify_sent && <Card mode={"outline"}>
                            <FormItem top={"Уведомление подписчиков"}>
                                <Input defaultValue={"Отправлено " + form.notify_date} disabled={true}/>
                            </FormItem>
                            <Spacing size={12}/>
                        </Card>}
                        {!form.notify_sent && <Card mode={"outline"}>
                            <FormLayoutGroup mode={"horizontal"}>
                                <FormItem top="Уведомление подписчиков">
                                    {isPremium && <NativeSelect name={"notify_days"} onChange={handleInputChange}
                                                                key={"notify_days" + form.id}
                                                                defaultValue={form.notify_days ?? -1}>
                                        <option value={-1}>Не уведомлять</option>
                                        {daysBeforeEvent() >= 1 && <option value={1}>За 1 день</option>}
                                        {daysBeforeEvent() >= 7 && <option value={7}>За 7 дней</option>}
                                        <option value={-2}>Выбрать дату</option>
                                    </NativeSelect>}
                                    {!isPremium && <Input defaultValue={"Не уведомлять"} disabled={true}
                                                          after={!isPremium && tooltip}/>}

                                </FormItem>
                                {parseInt(form.notify_days) === -2 &&
                                    <FormItem top="Дата" status={errors.notify_date ? "error" : "default"}
                                              bottom={errors.notify_date}>
                                        <Popover content={<Calendar disablePast={true}
                                                                     shouldDisableDate={shouldDisableNotifyDate}
                                                                     value={notifyDate}
                                                                     onChange={handleNotifyDateChange}
                                                                     size={"l"}/>}>
                                            <Button size={"l"} stretched={true} appearance={"neutral"}
                                                    mode={"outline"}
                                                    before={
                                                        <Icon16ArrowTriangleDown/>}>{form.notify_date ?? "Дата"}</Button>
                                        </Popover>
                                        <HiddenField name={"notify_date"} defaultValue={form.notify_date ?? ""}/>
                                    </FormItem>}
                            </FormLayoutGroup>

                            {isPremium && showNotificationForm && <>
                                <FormItem top="Текст уведомления*">
                                    <Textarea key={'Textarea_notify' + form.id} defaultValue={form.notify_message ?? ""}
                                              name={"notify_message"}
                                              maxLength={200}
                                              required={true}
                                              onChange={handleInputChange}/>
                                </FormItem>

                                {!!form.city_id && form.city_id > 0 &&
                                    <Checkbox defaultChecked={!!form.notify_cities_flag}
                                              name={"notify_cities_flag"}
                                              onChange={handleInputChange}>
                                        Рассылать только подписчикам из города {form.city}
                                    </Checkbox>}
                            </>}

                            <Spacing size={12}/>
                        </Card>}
                    </Div>

                    <Div>
                        <Card mode={"outline"}>
                            <FormItem top="Ссылка на внешний ресурс (например, на сайт билетного оператора)">
                                <Input defaultValue={form.tickets_link || ''} name={"tickets_link"}
                                       pattern="https?://.+"
                                       placeholder="https://"
                                       maxLength="255"
                                       onChange={handleInputChange}/>
                            </FormItem>

                            <FormItem top="Название кнопки*">
                                <Input maxLength={15} defaultValue={form.tickets_button_title || ''} disabled={!isPremium}
                                       after={!isPremium && tooltip} name={"tickets_button_title"}
                                       required={true}
                                       onChange={handleInputChange}/>
                            </FormItem>
                            <Spacing size={12}/>
                        </Card>
                    </Div>

                    <Div>
                        <Card mode={"outline"}>

                            <FormItem top="Ссылка на внутренний ресурс (например, на встречу VK)">
                                <Input defaultValue={form.event_link || ''} name={"event_link"}
                                       placeholder="https://vk.com/"
                                       pattern="https?://vk.(com|ru)/(?!away.php).+"
                                       maxLength="255"
                                       onChange={handleInputChange}/>
                            </FormItem>

                            <FormItem top="Название кнопки*">
                                <Input maxLength={15} defaultValue={form.event_button_title || ''} disabled={!isPremium}
                                       after={!isPremium && tooltip} name={"event_button_title"}
                                       required={true}
                                       onChange={handleInputChange}/>
                            </FormItem>
                            <Spacing size={12}/>
                        </Card>
                    </Div>

                    <Div>
                        <Button size={"l"} type={"submit"} stretched={smallScreen}>Сохранить</Button>
                    </Div>
                </form>
            </SplitCol>

            {!smallScreen && <SplitCol width={"40%"}>
                <Div>
                    <Caption>
                        * Чтобы опубликовать ваше событие, пожалуйста, заполните все поля отмеченные звездочкой.
                    </Caption>
                </Div>
            </SplitCol>}

            {snackbar}
        </SplitLayout>}
    </>
}

EventForm.propTypes = {
    isEdit: PropTypes.bool,
    isCopy: PropTypes.bool,
};

EventForm.defaultProps = {
    isEdit: false,
    isCopy: false
};

export default EventForm