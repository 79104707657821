import React, { useEffect } from "react";
import { useIsCommon } from "hooks/params";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { PanelSpinner } from "@vkontakte/vkui";
import { routes, SCHEDULE_TABS } from "routes";
import { useTip } from "api/tips/hooks";
import { useUser } from "api/user/hooks";

const Resolver = (props) => {
  const routeNavigator = useRouteNavigator();
  let isCommon = useIsCommon();
  useUser()
  useTip()
  useEffect(() => {
    if (isCommon) {
      void routeNavigator.replace(routes.default_view.app_schedule);
    } else {
      void routeNavigator.replace(routes.default_view.group_app.schedule, {tab: SCHEDULE_TABS.UPCOMING});
    }
  }, [isCommon]);
  return (
    <PanelSpinner height={600} nav={props.nav}>
      ...
    </PanelSpinner>
  );
};

export default Resolver;