import { Icon24CrownOutline } from "@vkontakte/icons";
import React from "react";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";
import { Tooltip } from "@vkontakte/vkui";

const PremiumTooltip = () => {
  let routeNavigator = useRouteNavigator();

  const openPremium = () => {
    void routeNavigator.push(routes.default_view.group_app.premium);
  };

  const tooltipText = <>
    Нужен премиум-аккаунт.
    <br />
    <span style={{ textDecoration: "underline" }}>Подробнее →</span>
  </>;

  return <Tooltip
    style={{ cursor: "pointer" }}
    onClick={openPremium}
    text={tooltipText}
  >
    <Icon24CrownOutline
      onClick={openPremium}
      fill={"var(--vkui--color_icon_secondary)"}
      style={{ cursor: "pointer" }}
    />
  </Tooltip>;
};

export default PremiumTooltip;