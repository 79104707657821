import axios from "axios";

export const http = axios.create({
    headers: {
        // Прикрепляем заголовок, отвечающий за параметры запуска.
        Authorization: `Bearer ${encodeURIComponent(window.bearer)}`,
        Accept: `application/json`,
        "x-app-version": process.env.REACT_APP_VERSION
    },
    baseURL: process.env.REACT_APP_API_BASE_URL + 'api/'
})


const request = async function(options) {
    const onSuccess = (response) => {
        const {
            data: data,
        } = response;
        return data;
    };

    return http
      .request(options)
      .then(onSuccess);
};

export default request


export const handleError = (error) => {
    if (error.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return {
            data: error.response.data,
            status: error.response.status,
            headers: error.response.headers,
        }
    } else if (error.request) {
        // The request was made but no response was received
        // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return {
            request: error.request
        }
    } else {
        return {
            error: error.message
        }
    }
}