import {
  createHashRouter,
  createModal,
  createPanel,
  createTab,
  createView,
  RoutesConfig,
} from "@vkontakte/vk-mini-apps-router";

export const DEFAULT_ROOT = "default_root";

export const DEFAULT_VIEW = "default_view";

export const DEFAULT_VIEW_PANELS = {
  RESOLVER: "resolver",
  MAIN_APP: "app_schedule",
  GROUP_APP: "group_app",
  EVENT: "event",
  ADD_EVENT: "add_event",
  COPY_EVENT: "copy_event",
  EDIT_EVENT: "edit_event",
};

export const PREMIUM_MODALS = {
  EMAIL: "email",
  ABOUT: "about",
};

export const WIDGET_MODALS = {
  ADMIN_ONLY: "admin_only",
};

export const GROUP_APP_TABS = {
  SCHEDULE: "schedule",
  PREMIUM: "premium",
  MAILINGS: "mailings",
  WIDGET: "widget",
  SETTINGS: "settings",
  EMBED: "embed",
};

export const SCHEDULE_TABS = {
  UPCOMING: "upcoming",
  PAST: "past",
  TAG: "tag",
}

export const WIDGET_TABS = {
  ABOUT: "about",
  SETTINGS: "settings",
};

export const SETTINGS_TABS = {
  IMAGES: "images",
  TAGS: "tags",
  PLACE: "place",
  BILLING: "billing",
};

export const MAILINGS_TABS = {
  ABOUT: "about",
  SUBSCRIBERS: "subscribers",
};

export const EVENT_MODALS = {
  SHARE_LINK: "share_link",
};

export const COMMON_MODALS = {
  CROP_IMAGE: "crop_image",
  BADGE_SELECTOR: "badge_selector",
};

export const TAGS_MODALS = {
  CREATE: "create",
  EDIT: "edit",
};

export const routes = RoutesConfig.create([
  createView(DEFAULT_VIEW, [
    createPanel(DEFAULT_VIEW_PANELS.RESOLVER, "/"),

    createPanel(DEFAULT_VIEW_PANELS.EVENT, `/${DEFAULT_VIEW_PANELS.EVENT}/:event`, [], ["event"]),

    createPanel(DEFAULT_VIEW_PANELS.EDIT_EVENT, `/${DEFAULT_VIEW_PANELS.EDIT_EVENT}/:event`, [], ["event"]),
    createPanel(DEFAULT_VIEW_PANELS.ADD_EVENT, `/${DEFAULT_VIEW_PANELS.ADD_EVENT}`),
    createPanel(DEFAULT_VIEW_PANELS.COPY_EVENT, `/${DEFAULT_VIEW_PANELS.COPY_EVENT}/:event`, [], ["event"]),

    createPanel(DEFAULT_VIEW_PANELS.MAIN_APP, `/${DEFAULT_VIEW_PANELS.MAIN_APP}`),

    createPanel(DEFAULT_VIEW_PANELS.GROUP_APP, `/${DEFAULT_VIEW_PANELS.GROUP_APP}`, [
      createTab(GROUP_APP_TABS.SCHEDULE, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.SCHEDULE}/:tab`, [], ["tab"]),
      createTab(GROUP_APP_TABS.PREMIUM, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.PREMIUM}`, [
        createModal(PREMIUM_MODALS.ABOUT, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.PREMIUM}/${PREMIUM_MODALS.ABOUT}`),
      ]),
      createTab(GROUP_APP_TABS.MAILINGS, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.MAILINGS}/:tab`, [], ["tab"]),
      createTab(GROUP_APP_TABS.WIDGET, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.WIDGET}/:tab`, [], ["tab"]),
      createTab(GROUP_APP_TABS.SETTINGS, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.SETTINGS}/:tab`, [
        createModal(TAGS_MODALS.CREATE, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.SETTINGS}/:tab/${TAGS_MODALS.CREATE}/`, ["tab"]),
        createModal(TAGS_MODALS.EDIT, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.SETTINGS}/:tab/${TAGS_MODALS.EDIT}/:tag`, ["tag", "tag"]),
      ], ["tab"]),
      createTab(GROUP_APP_TABS.EMBED, `/${DEFAULT_VIEW_PANELS.GROUP_APP}/${GROUP_APP_TABS.EMBED}`),
    ]),
  ]),
]);

export const router = createHashRouter(routes.getRoutes());