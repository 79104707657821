import { Avatar, Banner, Button, Div } from "@vkontakte/vkui";
import React, { useEffect } from "react";
import { useIsPremium, usePremiumDaysExpiration } from "hooks/user";
import Premium from "assets/images/premium.svg";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";
import { useTip } from "api/tips/hooks";
import { logPremiumTipViewByGroup, logTipViewByGroup } from "utils/analytics";
import "./Tip.css";

const Tip = () => {

    let routeNavigator = useRouteNavigator();

    function premium() {
        void routeNavigator.replace(routes.default_view.group_app.premium);
    }

    let { data: tip } = useTip();

    let isPremium = useIsPremium();
    let daysExpiration = usePremiumDaysExpiration();
    const showPremiumNotification = isPremium && daysExpiration < 8;

    const asideMode = tip && tip.id ? "dismiss" : null;
    const showTip = tip && !tip.id;

    useEffect(() => {
        if (showTip) {
            logTipViewByGroup();
        } else if (!showTip && showPremiumNotification) {
            logPremiumTipViewByGroup();
        }
    }, [showTip, showPremiumNotification]);

    return (<>
        {showTip && <Div>
            <Banner
          asideMode={asideMode}
          before={<Avatar
            className={"TipAvatar"}
            size={28}
          >
              <span>!</span>
          </Avatar>}
          text={<span dangerouslySetInnerHTML={{ __html: tip.message }} />}
            />
        </Div>}
        {!showTip && showPremiumNotification && <Div>
            <Banner
              before={<Avatar
                src={Premium}
                size={48}
              ></Avatar>}
              text={<>Текущий период премиум-аккаунта подходит к концу. Не забудьте его продлить.</>}
              actions={<Button onClick={premium}>Продлить</Button>}
            /></Div>}
    </>);
};

export default Tip;