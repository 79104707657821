import { ModalCard, Spacing, Text } from "@vkontakte/vkui";
import Code from "components/Code/Code";
import React from "react";
import PropTypes from "prop-types";
import { useParams } from "@vkontakte/vk-mini-apps-router";
import { DEFAULT_VIEW_PANELS } from "routes";
import { getEventLink } from "utils/events";

const EventLinks = (props) => {

  const { event: eventId } = useParams({ panel: DEFAULT_VIEW_PANELS.EVENT }) ?? {};

  let linkToShare = getEventLink(eventId);

  return (
    <ModalCard
      dismissButtonMode={"inside"}
      header={"Ссылка на мероприятие"}
      id={props.id}
    >
      <Spacing size={16} />
        <Text weight={"regular"}>
          Прямая ссылка на мероприятие:
          <Spacing />
          <Code>
            {linkToShare}
          </Code>
        </Text>
      <Spacing size={16} />
        <Text weight={"regular"}>
          Ссылка для поста в виде текста (не забудьте поменять текст в скобках):
          <Spacing />
          <Code>
            [{linkToShare}|Текст ссылки]
          </Code>
        </Text>
    </ModalCard>
  )
}

EventLinks.propTypes = {
    id: PropTypes.string.isRequired
}

export default EventLinks;