import { useQuery } from "react-query";
import bridge from "@vkontakte/vk-bridge";
import { VK_API_VERSION } from "api/constants";

export const useSearchVkCities = (query, token) => {
  return useQuery(
    ["vk_city", { query: query, token: token }],
    () => {
      return bridge.send(
        "VKWebAppCallAPIMethod",
        {
          method: "database.getCities",
          params: {
            q: query,
            count: 25,
            v: VK_API_VERSION,
            access_token: token,
            lang: "ru",
            need_all: 0,
          },
        },
      );
    },
    {
      select: (data) => data.response.items,
    },
  );
};