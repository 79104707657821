import { Button, ModalCard } from "@vkontakte/vkui";
import React from "react";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { Icon36UserOutline } from "@vkontakte/icons";

const WidgetAdminOnlyModal = (props) => {

  const routeNavigator = useRouteNavigator();


  return <ModalCard
    nav={props.nav}
    icon={<Icon36UserOutline
      width={56}
      height={56}
    />}
    header="Нужен администратор"
    subheader="Только администратор сообщества может включать и выключать виджет."
    actions={
      <Button
        size="l"
        mode="primary"
        stretched
        onClick={() => {
          void routeNavigator.hideModal();
        }}
      >
        Спасибо, сейчас позову
      </Button>
    }
  ></ModalCard>;
};

export default WidgetAdminOnlyModal;