import PropTypes from "prop-types";
import {Avatar, Button, ButtonGroup, Link, RichCell} from "@vkontakte/vkui";
import {
  Icon28MoreVertical, Icon28SmileAddOutline,
} from "@vkontakte/icons";
import React from "react";
import { useIsSmallScreen } from "hooks/adaptivity";
import IconBadge from "components/IconBadge";
import { COMMON_MODALS } from "routes";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { LifecycleStorage } from "utils/badge";
import { useIsAppAdmin } from "hooks/params";

const EventItem = (props) => {
  let isAppAdmin = useIsAppAdmin();
  let smallScreen = useIsSmallScreen();
  let routeNavigator = useRouteNavigator();
  const event = props.event;

  const showAddIcon = !props.event.icon_name && isAppAdmin;
  const showBadgeIcon = !!props.event.icon_name;

  const AddIcon = () => <Avatar.Badge
    background={"stroke"}
    onClick={(e) => {
      e.stopPropagation();
      LifecycleStorage.setCurrentEvent(event.id);
      routeNavigator.showModal(COMMON_MODALS.BADGE_SELECTOR);
    }}
  >
    <Icon28SmileAddOutline
      width={28}
      height={28}
    />
  </Avatar.Badge>;

  const BadgeIcon = () => <Avatar.Badge
    background={"stroke"}
    onClick={isAppAdmin ? (e) => {
      e.stopPropagation();
      LifecycleStorage.setCurrentEvent(event.id);
      routeNavigator.showModal(COMMON_MODALS.BADGE_SELECTOR);
    } : null}
  >
    <IconBadge
      size={28}
      name={props.event.icon_name}
    />
  </Avatar.Badge>;


  return <RichCell
        hasHover={smallScreen}
        stoppropagation={"true"}
        onClick={() => props.openEvent(event.id)}
        before={<Avatar
          size={smallScreen ? 64 : 96}
          src={event.image}
          children={
            (showAddIcon && <AddIcon />) || (showBadgeIcon && <BadgeIcon />)
          }
        />}
        text={event.date_time}
        after={<Link
          stoppropagation={"true"}
          onClick={(e) => {
            props.openManage?.(event.id);
            e.stopPropagation();
          }}
          getRootRef={props.manageRef}
        >
          <Icon28MoreVertical />
        </Link>}
        caption={event.full_place}
        actions={
            <ButtonGroup mode="horizontal" gap="s">
                {event.buttons
                  .sort((a, b) => (a.sort < b.sort ? 1 : -1))
                  .map((value, index) =>
                    <Button
                      key={index}
                      size={"s"}
                      target="_blank"
                      stoppropagation={"true"}
                      onClick={e => {
                        e.stopPropagation();
                      }}
                      mode={"primary"}
                      href={value.link}
                    >
                      {value.title}
                    </Button>,
                  )}
            </ButtonGroup>}
  >
    {event.title}
  </RichCell>

}

EventItem.propTypes = {
  event: PropTypes.object.isRequired,
  openEvent: PropTypes.func.isRequired,
  manageRef: PropTypes.func,
  openManage: PropTypes.func,
  showBadgeSelector: PropTypes.bool,
};

export default EventItem