import React from "react";
import "./PayWall.css";
import { Button, Placeholder } from "@vkontakte/vkui";
import { Icon20CrownCircleFillVkDating } from "@vkontakte/icons";
import { useIsPremium } from "hooks/user";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";

export const PayWall = (props) => {
  let isPremium = useIsPremium();
  let routeNavigator = useRouteNavigator();

  const openPremium = () => {
    void routeNavigator.push(routes.default_view.group_app.premium);
  };

  return <div className={"PayWall_wrapper"}>
    <div className={isPremium ? "PayWall_content" : "PayWall_content disabled"}>
      {props.children}
    </div>

    {!isPremium && <div className={"PayWall"}>
      <Placeholder
        header="Нужен премиум-аккаунт"
        action={<Button
          onClick={openPremium}
          size="m"
        >Подробнее</Button>}
        icon={<Icon20CrownCircleFillVkDating
          width={56}
          height={56}
        />}
      >
        Всего за 183 рубля в месяц!
      </Placeholder>
    </div>
    }
  </div>;
};