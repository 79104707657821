import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { COMMON_MODALS } from "routes";
import { useContext } from "react";
import {
  CropperModalParamsContext,
  CropperModalParamsDispatchContext,
  CropperResultContext, CropperResultDispatchContext,
} from "components/ImageInput/ImageDataProvider";


export function useCropperModal() {
  let routeNavigator = useRouteNavigator();

  const setCropperModalParams = useContext(CropperModalParamsDispatchContext);

  const cropperModalParams = useContext(CropperModalParamsContext);

  /**
   * @param key {string}
   * @param cropperParams {CropperParams}
   */
  function showCropperModal(key, cropperParams) {

    let paramsPair = {
      [key]: cropperParams,
    };

    setCropperModalParams(
      {
        currentKey: key,
        imageParams: Object.assign(cropperModalParams.imageParams, paramsPair),
      },
    );

    void routeNavigator.showModal(COMMON_MODALS.CROP_IMAGE);
  }

  return {
    show: showCropperModal,
  };
}

/**
 *
 * @return {CropperParams|null}
 */
export function useCropperModalParams() {
  let params = useContext(CropperModalParamsContext);
  let currentKey = params.currentKey;
  if (currentKey == null) {
    return null;
  }
  return params.imageParams[currentKey];
}


/**
 * @param {string} key
 */
export function useCropperResult(key) {
  let cropperResult = useContext(CropperResultContext);
  return {
    result: cropperResult.results[key],
  };
}

/**
 * @param {string} key
 * @return {{set: (Object) => void, remove: () => void}}
 */
export function useDispatchCropperResult(key = undefined) {
  let cropperResult = useContext(CropperResultContext);
  let cropperResultDispatch = useContext(CropperResultDispatchContext);
  let params = useContext(CropperModalParamsContext);
  let currentKey = key ? key : params.currentKey;

  return {
    set: (result) => {
      cropperResultDispatch({
        results: Object.assign(cropperResult.results, { [currentKey]: result }),
      });
    },
    remove: () => {
      let results = cropperResult.results;
      delete results[currentKey];
      cropperResultDispatch({
        results: results,
      });
    },
  };
}