import PropTypes from "prop-types";

const Row = (props) => {
    return <div style={{display: "flex", alignItems: "center"}}>{props.children}
        {!!props.after && <div style={{width: 8}}></div>}
        {props.after}
    </div>
}

Row.propTypes = {
    after: PropTypes.node
}

export default Row