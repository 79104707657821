import { Banner, Button, Div } from "@vkontakte/vkui";
import React, { useEffect, useState } from "react";
import ReminderImg from "../../assets/icons/reminder.svg";
import OpenDateImg from "../../assets/icons/opendate.svg";
import PinImg from "../../assets/icons/pin.svg";
import PastImg from "../../assets/icons/past.svg";
import CategoryImg from "../../assets/icons/categorys.svg";
import WidgetImg from "../../assets/icons/widget.svg";
import ButtonsImg from "../../assets/icons/buttons.svg";
import { useIsPremium } from "hooks/user";
import VKActions from "../../utils/vk_actions";
import { useGroupId, useIsAppAdmin, useIsProfileApp } from "hooks/params";
import { useUser } from "api/user/hooks";

const PremiumBanner = () => {

  let { data: user } = useUser();
  let isPremium = useIsPremium();
  let isAdmin = useIsAppAdmin();
  let isProfileApp = useIsProfileApp();
  let groupId = useGroupId();

  const [timestamp, setTimestamp] = useState(new Date().getTime());

  useEffect(() => {
    VKActions.getBannerCloseTimeStamp(groupId, function(keys) {
      console.log("keys", keys);
      if (!!keys) {
        setTimestamp(parseInt(keys));
      } else {
        setTimestamp(0);
      }
    });
  }, [groupId]);

  const close = () => {
    VKActions.setBannerCloseTimeStamp(groupId);
    setTimestamp(new Date().getTime());
  };

  const timeExpired = (new Date().getTime() - timestamp) > 14 * 24 * 60 * 60 * 1000;
  const shouldShow = !isProfileApp && isAdmin && user && !isPremium && timeExpired;


  const banners = [
    {
      image: ReminderImg,
      text: "Автоматические уведомления подписчиков вашей афиши о предстоящих мероприятиях",
    },
    {
      image: OpenDateImg,
      text: "Возможность создать мероприятие без конкретной даты проведения",
    },
    {
      image: PinImg,
      text: "Закрепление мероприятий вверху Афиши и Виджета вне зависимости от даты",
    },
    {
      image: PastImg,
      text: "Сохранение в вашей афише прошедших мероприятий старше одного года",
    },
    {
      image: CategoryImg,
      text: "Разделение мероприятий на категории и отображение их на отдельных вкладках Афиши",
    },
    {
      image: WidgetImg,
      text: "Расширенный виджет с большими картинками на странице вашего сообщества",
    },
    {
      image: ButtonsImg,
      text: "Переименование кнопок в Афише и Виджете",
    },
  ];

  const [banner, setBanner] = useState(banners[Math.floor(Math.random() * banners.length)]);

  return <>{shouldShow && <Div>
    <Banner
      mode="image"
      size="m"
      header={
        <div style={{ maxWidth: 600 }}>
          {banner.text}
        </div>
      }
      background={
        <div
          style={{
            backgroundColor: "#5b9be6",
            backgroundImage:
              `url(${banner.image})`,
            backgroundPosition: "right center",
            backgroundSize: "auto 80%",
            backgroundRepeat: "no-repeat",
          }}
        />
      }
      asideMode="dismiss"
      onDismiss={close}
      actions={
        <Button
          href={"#/group_app/premium"}
          appearance="overlay"
          size="m"
        >
          Подробнее
        </Button>
      }
    />
  </Div>
  }</>;
};

export default PremiumBanner;