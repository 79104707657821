import request from "api/axiosConfig";

export const EventsApi = {

  app_list: async function(cityId, dateFrom) {
    let url = `/schedule/general/${cityId}`;
    return await request({
      url: url,
      method: "POST",
      data: {
        date_from: dateFrom,
      },
    });
  },


  app_list_paginated: async function(dateFrom, pageUrl) {
    return await request({
      url: pageUrl,
      method: "POST",
      data: {
        date_from: dateFrom,
      },
    });
  },

  group_list: async function(type, tag) {
    let url = `/frame/schedule/${type}`;
    if (tag) {
      url = url + `/${tag}`;
    }
    return await request({
      url: url,
      method: "GET",
    });
  },

  group_list_paginated: async function(pageUrl) {
    return await request({
      url: pageUrl,
      method: "GET",
    });
  },

  get: async function(id) {
    return await request({
      url: `/frame/schedule/${id}`,
      method: "GET",
    });
  },

  delete: async function(id) {
    return await request({
      url: `/frame/schedule/${id}`,
      method: "DELETE",
    });
  },

  info: async function(id) {
    return await request({
      url: `/frame/schedule/info/${id}`,
      method: "GET",
    });
  },

  place_suggestions: async function() {
    return await request({
      url: `/frame/utils/suggestions`,
      method: "GET",
    });
  },

  set_icon_name: async function(id, icon_name) {
    return await request({
      url: `/frame/schedule/${id}/icon`,
      method: "POST",
      data: {
        icon_name: icon_name,
      },
    });
  },
};