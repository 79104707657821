import { useUser } from "api/user/hooks";
import queryClient from "utils/queryClient";


export const useIsPremium = () => {
  let { data: user } = useUser();
  return user && user.premium_enabled;
}

export const useIsUserLoaded = () => {
  let { data: user } = useUser();
  return !!user;
};

export const usePremiumDaysExpiration = () => {
  let { data: user } = useUser();
  return user && user.premium_days;
}

export const useIsSubscriptionsEnabled = () => {
  let { data: user } = useUser();
  return user && user.subscriptions_enabled;
}

export const useIsSubscribed = () => {
  let { data: user } = useUser();
  return user && user.subscribed;
}

export const useUserId = () => {
  let { data: user } = useUser();
  return user?.id;
}

export const useWidget = () => {
  let { data: user } = useUser();
  return user && user.vk_widget;
}

export const useSubscribers = () => {
  let { data: user } = useUser();
  return user?.subscribers;
}

export const useEmail = () => {
  let { data: user } = useUser();
  return user?.email;
}

export const useTags = () => {
  let { data: user } = useUser();
  return user?.tags;
}

export const useIsPlace = () => {
  let { data: user } = useUser();
  return user?.type === "PLACE";
}

export const usePlace = () => {
  let { data: user } = useUser();
  return user?.place_info;
}

export const useDefaultImage = () => {
  let { data: user } = useUser();
  return user?.default_image_src;
};

export const userReload = () => {
  void queryClient.invalidateQueries("user");
};