import { Button, Div, FormItem, Input, ModalCard } from "@vkontakte/vkui";
import React, { useState } from "react";
import { useEmail } from "hooks/user";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { useMutateEmail } from "api/user/hooks";

const EmailForm = (props) => {

    const routeNavigator = useRouteNavigator();
    let [email, setEmail] = useState(useEmail() || "")

    let { mutateAsync } = useMutateEmail();

    function handleSubmit(e) {
        e.preventDefault()
        mutateAsync(email)
          .then(() => {
              void routeNavigator.hideModal();
          })
          .catch(reason => {
              console.log(reason);
          })
    }

    return <ModalCard
      header={"Укажите email для отправки квитанции об оплате:"}
      nav={props.nav}
    >

        <form onSubmit={handleSubmit}>
            <FormItem>
                <Input
                  defaultValue={email}
                  name={"email"}
                  required={true}
                  onChange={e => setEmail(e.target.value)}
                  placeholder={"Email"}
                  type={"email"}
                  pattern="[^@\s]+@[^@\s]+\.[^@\s]+"
                  maxLength="200"
                />
            </FormItem>
            <Div>
                <Button
                  stretched={true}
                  size={"l"}
                  type={"submit"}
                >Сохранить</Button>
            </Div>
        </form>
    </ModalCard>;
}

export default EmailForm