import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import { UserApi } from "api/user/api";
import queryClient from "utils/queryClient";
import { useGroupId, useIsCommon, useTS } from "hooks/params";

export const useUser = () => {
  return useQuery(
    ["user"],
    () => UserApi.get(),
    {
      enabled: !useIsCommon(),
      staleTime: Infinity,
    },
  );
};

export const useMutateEmail = () => {
  return useMutation(
    (email) => UserApi.post_email(email),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries("user");
      },
    },
  );
};
export const useMutateWidget = () => {
  return useMutation(
    (enable) => UserApi.widget_toggle(enable),
    {
      onSuccess: () => {
        void queryClient.invalidateQueries("user");
      },
    },
  );
};

export const useSubscribersList = () => {
  let groupId = useGroupId();
  let ts = useTS();
  return useInfiniteQuery(
    ["subscribers", { ts: ts, groupId: groupId }],
    ({ pageParam: pageUrl }) => pageUrl ? UserApi.subscribers_list_paginated(pageUrl) : UserApi.subscribers_list(),
    {
      select: (data) => data,
      staleTime: Infinity,
    },
  );
};

export const usePaymentsList = () => {
  let groupId = useGroupId();
  let ts = useTS();
  return useInfiniteQuery(
    ["payments", { ts: ts, groupId: groupId }],
    ({ pageParam: pageUrl }) => pageUrl ? UserApi.payments_list_paginated(pageUrl) : UserApi.payments_list(),
    {
      select: (data) => data,
    },
  );
};
