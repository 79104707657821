import { Button, IconButton, ModalCard } from "@vkontakte/vkui";
import React from "react";
import PropTypes from "prop-types";

import "pages/modal/BadgeSelector.css";
import IconBadge from "components/IconBadge";
import { Badges, LifecycleStorage } from "utils/badge";
import { useSetIconEvent } from "api/events/hooks";
import { useRouteNavigator } from "@vkontakte/vk-mini-apps-router";
import { PayWall } from "components/paywall/PayWall";
import { useIsPremium } from "hooks/user";

const BadgeSelector = (props) => {
  let isPremium = useIsPremium();

  let routeNavigator = useRouteNavigator();
  let currentEvent = LifecycleStorage.currentEvent;

  let { mutateAsync: setIconEvent } = useSetIconEvent();

  function iconClicked(name) {
    void setIconEvent({ id: currentEvent, icon_name: name })
      .then(() => {
        void routeNavigator.hideModal();
      });
  }

  return (
    <ModalCard
      dismissButtonMode={"inside"}
      header={isPremium ? "Выбрать иконку" : null}
      id={props.id}
    >
      <PayWall>
        <div className={"BadgeSelector_content"}>
          {Badges.map((value) =>
            <IconButton
              key={value}
              onClick={(e) => {
                iconClicked(value);
              }}
            >
              <IconBadge name={value} />
            </IconButton>,
          )}
        </div>
        <Button
          onClick={() => iconClicked(null)}
          size="l"
          mode="primary"
          appearance={"neutral"}
          stretched
        >Убрать иконку</Button>
      </PayWall>
    </ModalCard>
  );
};

BadgeSelector.propTypes = {
  id: PropTypes.string.isRequired,
};

export default BadgeSelector;