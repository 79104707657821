import { useEffect, useState } from "react";
import { singletonHook } from "react-singleton-hook";
import bridge from "@vkontakte/vk-bridge";
import { isDev } from "utils/development";


let initialState = {
  city: {},
  cityLoading: true,
  selectedCity: 0,
};

let globalSetReload = () => {
  throw new Error(`you must useUserCity before setting its state`);
};

export let setSelectedCity = (city) => {
  throw new Error(`you must implement before setting its state`);
};

const useUserCity = singletonHook(initialState, () => {
  if (isDev()) {
    return {
      city: {
        vk_city_id: 1,
        city: "Москва",
        selectedCity: 1,
      },
      cityLoading: false,
    };
  }

  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const [reload, setReload] = useState(Date.now());

  globalSetReload = setReload;
  setSelectedCity = (city) => {
    setData({ ...data, selectedCity: city });
  };

  useEffect(() => {
    const fetchData = async () => {
      const city = await (async () => {
        try {
          // throw Error("")
          const {
            city: { id, title },
          } = await bridge.send("VKWebAppGetUserInfo");
          return {
            vk_city_id: id || 1,
            city: title || "Москва",
            selectedCity: id || 1,
          };
        } catch (error) {
          console.error(error);
          return {
            vk_city_id: 1,
            city: "Москва",
            selectedCity: 1,
          };
        }
      })();

      setData(city);
      setLoading(false);
    };

    fetchData();
  }, [reload]);

  return {
    city: data,
    cityLoading: loading,
  };
});

export const useSelectedCity = () => {
  return useUserCity().city.selectedCity || 1;
};
export default useUserCity;

export const userCityReload = () => globalSetReload(Date.now());