import PropTypes from "prop-types";
import {
  ActionSheet,
  ActionSheetItem,
  AdaptiveIconRenderer,
  Button,
  CellButton,
  Div,
  Link,
  PanelSpinner,
  Placeholder,
  Separator,
  Spacing,
  Spinner,
  SplitCol,
  SplitLayout,
} from "@vkontakte/vkui";
import React, { useEffect, useRef, useState } from "react";
import EventItem from "components/list/EventItem";
import { useIsAppAdmin } from "hooks/params";
import ErrorSnack from "components/ErrorSnack";
import { useRouteNavigator, useSearchParams } from "@vkontakte/vk-mini-apps-router";
import { routes } from "routes";
import { useDeleteEvent, useGroupList } from "api/events/hooks";
import { useInView } from "react-intersection-observer";
import {
    Icon20CopyOutline,
    Icon20DeleteOutline, Icon20ShareOutline,
    Icon20WriteOutline, Icon28CopyOutline,
    Icon28DeleteOutline,
    Icon28EditOutline, Icon28ShareOutline,
} from "@vkontakte/icons";
import VKActions from "utils/vk_actions";
import { getEventLink } from "utils/events";
import { useIsPremium, useIsUserLoaded } from "hooks/user";

const GroupListTour = (props) => {
  let routeNavigator = useRouteNavigator();

  const [snackbar, setSnackbar] = useState(null);
  const [popout, setPopout] = useState(null);
  const { ref: showMoreRef, inView } = useInView();

  let { mutateAsync: deleteEvent } = useDeleteEvent();

  const [params] = useSearchParams();
  const activeTag = params.get("tag");
  const isAdmin = useIsAppAdmin();
  const isPremium = useIsPremium();
  const isUserLoaded = useIsUserLoaded();

    const {
        data,
        isLoading,
        error,
        isFetchingNextPage,
        fetchNextPage,
        hasNextPage,
    } = useGroupList(props.type, activeTag)

    const hasData = data?.pages[0]?.data?.length > 0;

    const itemsRef = useRef([]);

    useEffect(() => {
        if (inView) {
            void fetchNextPage();
        }
    }, [inView]);

    const openEvent = (id) => {
        void routeNavigator.push(routes.default_view.event, { event: id });
    }

    const editEvent = (id) => {
        void routeNavigator.push(routes.default_view.edit_event, { event: id });
    }

    const copyEvent = (id) => {
        void routeNavigator.push(routes.default_view.copy_event, { event: id });
    }

    function addEvent() {
        void routeNavigator.push(routes.default_view.add_event)
    }

    const shareEvent = function(id) {
        VKActions.share(
          getEventLink(id),
        );
    };

    const showPlaceholder = !isLoading && !hasData;
    const showAdminPlaceholder = showPlaceholder && isAdmin;

    const showActualPlaceholder = showPlaceholder && props.type === "actual" && !isAdmin;
    const showCommonPlaceholder = showPlaceholder && !isAdmin && !showActualPlaceholder;

    function deleteEventClicked(eventId) {
        deleteEvent(eventId)
          .catch(reason => {
            setSnackbar(<ErrorSnack onClose={() => setSnackbar(null)} reason={reason}/>)
        })
    }

    const openMenu = (event, index) => {
        if (isAdmin) {
            openManageMenu(event, index);
        } else {
            openMoreMenu(event, index);
        }
    };

  const openManageMenu = (event, index) => setPopout(
      <ActionSheet
        onClose={() => setPopout(null)}
        iosCloseItem={
            <ActionSheetItem
              mode="cancel"
            >
                Отменить
            </ActionSheetItem>
        }
        toggleRef={itemsRef.current[index]}
      >
          <ActionSheetItem
            onClick={() => editEvent(event.id)}
            before={
                <AdaptiveIconRenderer
                  IconCompact={Icon20WriteOutline}
                  IconRegular={Icon28EditOutline}
                />
            }
          >
              Редактировать
          </ActionSheetItem>

          <ActionSheetItem
            onClick={() => shareEvent(event.id)}
            before={
                <AdaptiveIconRenderer
                  IconCompact={Icon20ShareOutline}
                  IconRegular={Icon28ShareOutline}
                />
            }
          >
              Поделиться
          </ActionSheetItem>

          <ActionSheetItem
            onClick={() => copyEvent(event.id)}
            before={
                <AdaptiveIconRenderer
                  IconCompact={Icon20CopyOutline}
                  IconRegular={Icon28CopyOutline}
                />
            }
          >
              Копировать
          </ActionSheetItem>

          <ActionSheetItem
            mode="destructive"
            onClick={() => deleteEventClicked(event.id)}
            before={
                <AdaptiveIconRenderer
                  IconCompact={Icon20DeleteOutline}
                  IconRegular={Icon28DeleteOutline}
                />
            }
          >
              Удалить
          </ActionSheetItem>
      </ActionSheet>,
    );

  const openMoreMenu = (event, index) => setPopout(
      <ActionSheet
        onClose={() => setPopout(null)}
        iosCloseItem={
            <ActionSheetItem
              mode="cancel"
            >
                Отменить
            </ActionSheetItem>
        }
        toggleRef={itemsRef.current[index]}
      >
          <ActionSheetItem
            onClick={() => shareEvent(event.id)}
            before={
                <AdaptiveIconRenderer
                  IconCompact={Icon20ShareOutline}
                  IconRegular={Icon28ShareOutline}
                />
            }
          >
              Поделиться
          </ActionSheetItem>
      </ActionSheet>,
    );

    return <SplitLayout popout={popout}>
        <SplitCol>
            {isLoading && <PanelSpinner />}
            {showAdminPlaceholder && <Placeholder
              action={<Button
                disabled={!isUserLoaded}
                onClick={addEvent}
                size="m"
              >Добавить мероприятие</Button>}
            >
                Здравствуйте!
                <br/>
                Как бесплатно создать афишу с гастролями для вашего сообщества,
                <br/>
                как это работает и для
                чего это надо читайте <Link href="https://vk.com/@dvizh_app-start" target="_blank">здесь</Link>
            </Placeholder>}

            {showActualPlaceholder && <Placeholder>
                Здравствуйте!
                <br/>
                На данный момент никаких мероприятий не запланировано.
            </Placeholder>}


            {showCommonPlaceholder && <Placeholder>
                Нет информации о мероприятиях
            </Placeholder>}

            {data && data.pages.map((page, pageNumber) => (
              <React.Fragment key={"page" + pageNumber}>
                  {page.data.map((event) => (
                    <React.Fragment key={event.id}>
                        <Separator />
                        <Spacing />
                        <EventItem
                          showBadgeSelector={isPremium && isAdmin}
                          key={"event" + event.id}
                          event={event}
                          openEvent={openEvent}
                          manageRef={el => itemsRef.current[event.id] = el}
                          openManage={(id) => openMenu(event, id)}
                        />
                        <Spacing />
                    </React.Fragment>
                  ))}
              </React.Fragment>
            ))}

            {isFetchingNextPage && <>
                <Separator/>
                <Div>
                    <Spinner size="small" style={{margin: "20px 0"}}/>
                </Div>
            </>}
            {hasNextPage && !isFetchingNextPage &&
                <>
                    <Separator/>
                    <Div
                      getRootRef={showMoreRef}
                    >
                        <CellButton
                          onClick={fetchNextPage}
                          centered
                        >
                            Показать еще
                        </CellButton>
                    </Div>
                </>
            }
            {error && <ErrorSnack onClose={() => setSnackbar(null)} reason={error}/>}
            {snackbar}
        </SplitCol>
    </SplitLayout>
}

GroupListTour.propTypes = {
    type: PropTypes.oneOf(['actual', 'past', 'tag']).isRequired
}

export default GroupListTour