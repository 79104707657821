import React, { ReactNode } from "react";
import VKActions from "utils/vk_actions";
import "components/Code/Code.css";

const Code = (props: { className?: string, children: ReactNode }) => {

  function copy() {
    let codeText: string = React.Children
      .map(props.children, (child) => {
        if (typeof child === "string") {
          return child;
        }
        return null;
      })
      ?.filter((item) => {
        return item != null;
      })
      ?.join("\n") ?? "";

    VKActions.copyText(codeText);
  }

  return (
    <div className="Code">
      <code className={props.className}>{props.children}</code>
      <div className="Code__CopyButtonWrapper">
        <div
          className="Code__CopyButton"
          onClick={copy}
        />
      </div>
    </div>
  );
};

export default Code;